<template>
  <v-main class="fullwidth fullheight bg_card" :class="$mq">
    <!-- PC 웹 & 모바일 -->
    <v-card
      flat
      tile
      class="mx-auto"
      min-width="320"
      :max-width="$store.state.web_max_width"
      color="transparent"
    >
      <v-row justify="center" class="title_root">
        <v-col cols="12" class="title_root_text font_black">
          {{ $ml.get("main_button_03") }}
        </v-col>
        <div class="title_root_text_line font_black"></div>
        <v-btn
          elevation="0"
          class="title_root_back font_black"
          icon
          v-on:click="$router.go(-1)"
        >
        </v-btn>
      </v-row>

      <v-row justify="center" class="main_root">
        <v-card
          :class="$mq == 'sm' ? 'rounded-xl' : ''"
          color="transparent"
          width="100%"
        >
          <v-row justify="center" class="main_title_1">
            <v-btn
              elevation="0"
              class="main_root_back"
              icon
              v-on:click="$router.go(-1)"
            >
            </v-btn>
            <v-col
              cols="12"
              class="main_title_1_text main_title_text_font_color"
            >
              {{ title }}
            </v-col>
          </v-row>

          <v-card
            elevation="0"
            min-height="250"
            class="d-flex flex-column align-center justify-center rounded-0 main_title_1_text"
            :class="$mq == 'sm' ? 'rounded-b-xl' : ''"
            :color="$mq == 'sm' ? '#FFFFFFBB' : '#00000066'"
          >
            <v-card
              v-if="$mq == 'sm'"
              elevation="0"
              min-height="30vw"
              width="70vw"
              color="transparent"
              class="d-flex align-center justify-center main_title_1_text"
            >
              {{ $ml.get("counsel_start_title") }}
            </v-card>
            <v-btn
              class="select_btn yes"
              :color="$mq == 'sm' ? 'rounded-lg' : 'rounded-xl'"
              v-on:click="$router.replace('/counsel_write')"
            >
              {{ $ml.get("counsel_start_yes_btn") }}
            </v-btn>

            <v-btn
              class="select_btn no"
              :color="$mq == 'sm' ? 'rounded-lg' : 'rounded-xl'"
              v-on:click="$router.replace('/counsel_login')"
            >
              {{ $ml.get("counsel_start_no_btn") }}
            </v-btn>

            <v-card
              v-if="$mq == 'sm'"
              elevation="0"
              height="80"
              class="d-flex align-center justify-center main_title_1_text"
            >
            </v-card>
          </v-card>
        </v-card>
      </v-row>
    </v-card>
  </v-main>
</template>

<script>
export default {
  data: function() {
    return {};
  },
  computed: {
    title() {
      return this.$mq == "sm"
        ? this.$ml.get("main_button_03")
        : this.$ml.get("counsel_start_title");
    }
  },
  methods: {},
  created() {},
  watch: {}
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/common";

.bg_card {
  background-image: url("../assets/bg_healing.png");
}

.select_btn {
  height: 60px !important;
  width: 400px;
  font: normal normal normal 18px/24px Maple !important;
  letter-spacing: 0px;
  border: solid 1px white;
  margin: 10px;
}

.select_btn.yes {
  background-color: $bg-color-counsel_start-yes-btn !important;
  color: $ft-color-counsel_start-yes-btn !important;
}

.select_btn.no {
  background-color: $bg-color-counsel_start-no-btn !important;
  color: $ft-color-counsel_start-no-btn !important;
}

.sm.bg_card {
  background-image: url("../assets/bg_healing_m.png");

  .select_btn {
    height: 16vw !important;
    width: 80vw;
    font: normal normal normal 4.6vw/6vw Maple !important;
  }
}
</style>
