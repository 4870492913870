<template>
  <div :class="$mq">
    <v-row
      align="center"
      justify="center"
      style="width:100%; height: auto; margin:auto; padding-bottom:30px;"
    >
      <v-col cols="12" sm="12" style="height:100%;">
        <swiper
          ref="mySwiper"
          :options="swiperOptions"
          :auto-update="true"
          :auto-destroy="true"
          :delete-instance-on-destroy="true"
          :cleanup-styles-on-destroy="true"
          style="height:100%;"
        >
          <swiper-slide
            v-for="(r_num, num) in healing_list_swiper_size()"
            :key="num"
          >
            <v-row class="swiper_root_margin">
              <v-col
                class="healing_list_thumbnail"
                md="3"
                sm="6"
                style="padding: 0px;"
                v-for="(item, index) in healing_list_swiper_list(num)"
                :key="index"
              >
                <v-btn
                  v-on:click="goNativeStressActivity(item)"
                  class="icon"
                  v-bind:style="{
                    'background-image': 'url(' + item.Thumbnail_url + ')'
                  }"
                >
                  <div class="icon_skin">
                    <span class="icon_text" v-html="item.title"></span>
                  </div>
                </v-btn>
              </v-col>
            </v-row>
          </swiper-slide>
        </swiper>
      </v-col>

      <v-col cols="12" sm="12" style="padding:0px;">
        <div
          class="swiper-pagination"
          slot="pagination"
          style="position: relative; z-index:0 !important; height: 30px;"
        >
          <div
            v-for="(item, index) in healing_list_swiper_size()"
            :key="index"
            class="swiper-item"
            :class="swiper_page_index == index ? 'h-on' : ''"
            style="z-index:0 !important; cursor:pointer;"
            v-on:click="swiper_move(index)"
          />
        </div>
      </v-col>

      <v-dialog
        :content-class="'popup thumb_popup'"
        v-model="healing_popup"
        width="860"
        persistent
        v-if="healing_popup && is_small"
      >
        <v-btn
          elevation="0"
          icon
          class="small_or_big_btn"
          width="80"
          height="80"
          v-on:click="small = false"
        >
          <img width="80" aspect-ratio="1" src="@/assets/btFull.png" />
        </v-btn>
        <!-- youtube -->
        <v-card
          v-if="healing_popup_gubun == 'youtube'"
          class="popup_root"
          style="padding:34px;"
        >
          <v-card-actions>
            <v-spacer />
            <iframe
              type="text/html"
              v-bind:src="healing_popup_url"
              scrolling="no"
              frameborder="0"
              style="border-radius:10px; z-index: 300; width:100%; height:calc(90vh - 330px);"
            ></iframe>
            <v-spacer />
          </v-card-actions>

          <v-row justify="center" class="title_menu">
            <v-hover
              v-slot="{ hover }"
              class="common_btn"
              style="cursor: pointer; margin: 0px 12px 0px 12px;"
            >
              <v-card
                :elevation="hover ? 12 : 2"
                class="button_1"
                :class="{ 'on-hover': hover }"
                v-on:click="$router.go(-1)"
              >
                <v-row
                  class="fullheight justify-center align-center button_text"
                  v-html="$ml.get('contents_close_btn')"
                >
                </v-row>
              </v-card>
            </v-hover>
          </v-row>
        </v-card>
        <!-- else -->
        <iframe
          v-else
          type="text/html"
          v-bind:src="healing_popup_url"
          scrolling="no"
          frameborder="0"
          width="100%"
          height="100%"
          style="z-index: 300;"
        ></iframe>
      </v-dialog>

      <v-dialog
        :content-class="'dialog_full'"
        v-model="healing_popup"
        persistent
        v-else-if="healing_popup"
      >
        <v-btn
          v-if="$mq != 'sm'"
          elevation="0"
          icon
          class="small_or_big_btn"
          width="80"
          height="80"
          v-on:click="small = true"
        >
          <img width="80" aspect-ratio="1" src="@/assets/btNormal.png" />
        </v-btn>
        <!-- youtube -->
        <v-card
          v-if="healing_popup_gubun == 'youtube'"
          class="popup_root"
          style="padding-top:34px;padding-left:10px;padding-right:10px;padding-bottom:34px;background:#000000;"
        >
          <v-card-actions>
            <v-spacer />
            <iframe
              type="text/html"
              allowfullscreen="allowfullscreen"
              v-bind:src="healing_popup_url"
              scrolling="no"
              frameborder="0"
              style="border-radius:10px; z-index: 300; width:100vw; height:66vw;background:#000000;"
            ></iframe>
            <v-spacer />
          </v-card-actions>

          <v-row justify="center" class="title_menu">
            <v-hover
              v-slot="{ hover }"
              class="common_btn"
              style="cursor: pointer; margin: 0px 12px 0px 12px;"
            >
              <v-card
                :elevation="hover ? 12 : 2"
                class="button_1"
                :class="{ 'on-hover': hover }"
                v-on:click="$router.go(-1)"
              >
                <v-row
                  class="fullheight justify-center align-center button_text"
                  v-html="$ml.get('contents_close_btn')"
                >
                </v-row>
              </v-card>
            </v-hover>
          </v-row>
        </v-card>
        <!-- else -->
        <iframe
          v-else
          type="text/html"
          width="100%"
          height="100%"
          v-bind:src="healing_popup_url"
          scrolling="no"
          frameborder="0"
          style="z-index: 300;margin-bottom: -10px;"
        ></iframe>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
let vm = null;

// iOS, Android
import { Capacitor, Plugins } from "@capacitor/core";
const { IntentPlugin } = Plugins;
const { Browser } = Plugins;

export default {
  components: {
    Swiper,
    SwiperSlide
  },
  props: ["healing_list"],
  created() {
    vm = this;

    //팝업이 띄워져있으면, 한칸 뒤로가기
    var target = this.$route.fullPath.substring(
      this.$route.fullPath.indexOf("#")
    );
    if (target == "#healing_popup") {
      this.$router.go(-1);
    }
  },
  data() {
    return {
      swiper_page_index: 0,
      swiperOptions: {
        shortSwipes: true,
        spaceBetween: 80,
        threshold: 40,
        longSwipesRatio: 0,
        pagination: {
          el: ".swiper-pagination"
        },
        on: {
          slideChange: function() {
            const realIndex = this.realIndex;

            vm.swiper_point_move(realIndex);
          }
        }
      },
      healing_popup: false,
      healing_popup_url: "",
      healing_popup_gubun: "",
      small: true
    };
  },

  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
    is_small() {
      if (this.small) {
        if (this.$mq != "sm") {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  },

  methods: {
    goNativeStressActivity: function(item) {
      if (Capacitor.isNative) {
        // Native
        if (item.content_gubun == "breath") {
          IntentPlugin.callStressActivity({
            url: item.content_url + "&is_simple=true"
          });
        } else {
          if (item.content_gubun == "youtube") {
            if (this.$store.state.platform == "android") {
              var index = this.healing_list.findIndex(function(it) {
                return it.idx === item.idx;
              });

              this.healing_popup_url = this.healing_list[index].content_url;
              this.healing_popup_gubun = this.healing_list[index].content_gubun;
              var open_url = this.healing_popup_url;
              Browser.open({ url: open_url });
            } else {
              // ios
              this.healing_view(item.idx);
            }
          } else {
            // order contents
            this.healing_view(item.idx);
          }
        }
      } else {
        // Web
        var isChrome = this.$browserDetect.isChrome;

        if (isChrome && this.$browserDetect.meta.ua.indexOf("Mobile") >= 0) {
          var index_web = this.healing_list.findIndex(function(it) {
            return it.idx === item.idx;
          });

          this.healing_popup_url = this.healing_list[index_web].content_url;
          this.healing_popup_gubun = this.healing_list[index_web].content_gubun;
          window.open(this.healing_popup_url, "_blank");
        } else {
          this.healing_view(item.idx);
        }
      }
    },
    swiper_point_move: function(index) {
      this.swiper_page_index = index;
    },
    swiper_move: function(index) {
      this.$refs.mySwiper.$swiper.slideTo(index, 1000);
      this.swiper_page_index = index;
    },
    healing_view: function(idx) {
      var index = this.healing_list.findIndex(function(item) {
        return item.idx === idx;
      });

      this.healing_popup_url = this.healing_list[index].content_url;
      this.healing_popup_gubun = this.healing_list[index].content_gubun;

      this.$router.push({ hash: "#healing_popup" });
    },
    healing_list_swiper_size: function() {
      return Math.ceil(this.healing_list.length / 8);
    },
    healing_list_swiper_list: function(num) {
      let check = Math.ceil(this.healing_list.length / 8);
      if (this.healing_list.length % 2 !== 0 && num + 1 === check) {
        let temp = this.healing_list.slice(num * 8, (num + 1) * 8);
        temp.push("");
        setTimeout(function() {
          if (vm.healing_list.length % 2 !== 0) {
            document.getElementsByClassName("healing_list_thumbnail")[
              vm.healing_list.length
            ].style.visibility = "hidden";
          }
        }, 0);
        return temp;
      }
      return this.healing_list.slice(num * 8, (num + 1) * 8);
    }
  },

  watch: {
    $route(to) {
      //팝업 띄움
      var target = to.fullPath.substring(to.fullPath.indexOf("#"));

      if (target == "#healing_popup") {
        this.healing_popup = true;
      } else if (target.indexOf("#") < 0) {
        this.healing_popup = false;
      }
    },
    healing_list() {
      //healing_list 값이 바뀌면, 첫페이지로 이동
      this.$refs.mySwiper.$swiper.slideTo(0, 0);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/common";
.icon {
  width: 180px;
  height: 180px !important;
  padding: 0px !important;
  margin: 12px 0px;
  border-radius: 8px;
  cursor: pointer;
  background-size: cover;
  background-position: center;

  font-family: Noto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  color: $ft-color-contents-icon-text;
}

.icon_skin {
  width: 180px;
  height: 180px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.4);
  display: table;
}

.icon_text {
  width: 160px;
  white-space: break-spaces;
  height: 44px;
  overflow: hidden;
  display: table-cell;
  vertical-align: middle;
  font: normal normal normal 22px/26px Maple;
}

.swiper-item {
  background-color: $bg-color-contents-swiper-item-border-off;
  display: inline-block;
  margin: 5px 5px 0px 5px;
  width: 44px;
  height: 7px;
  // border: 1px solid $bg-color-contents-swiper-item-border;
  // border-radius: 5px;
  opacity: 0.6;
}

.swiper-item.h-on {
  background: $bg-color-contents-swiper-item-border 0% 0% no-repeat padding-box;
  opacity: 1;
}

.swiper_root_margin {
  margin: 15px 18px;
}

/* popup */
.small_or_big_btn {
  position: absolute;
  margin: 35px 17px;
}

/* mobile */
.sm {
  .swiper_root_margin {
    margin: 0px 2vw;
  }

  .icon {
    width: 35vw;
    height: 35vw !important;
    // border-radius: 13vw;
    border-radius: 8px;
    margin: 2vw 0px;
  }

  .icon_skin {
    width: 35vw;
    height: 35vw !important;
    // border-radius: 13vw;
    border-radius: 8px;
    margin: 2vw 0px;
  }

  .icon_text {
    width: 23vw;
    height: 5vw;
    font: normal normal normal 3.4vw/4vw Maple;
  }

  .common_btn {
    height: 50px;
  }

  .swiper-item {
    width: 15px;
    height: 15px;
  }
}
</style>

<style lang="scss">
@import "src/assets/css/swiper-bundle.min";
</style>
