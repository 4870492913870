<template>
  <v-main class="fullwidth fullheight bg_card" :class="$mq">
    <!-- PC 웹 & 모바일 -->
    <v-card
      flat
      tile
      class="mx-auto"
      min-width="320"
      :max-width="$store.state.web_max_width"
      color="transparent"
      v-if="!review_detail_popup"
    >
      <v-row justify="center" class="title_root">
        <v-col cols="12" class="title_root_text">
          {{ $ml.get("main_button_05") }}
        </v-col>
        <div class="title_root_text_line"></div>
        <v-btn
          elevation="0"
          class="title_root_back"
          icon
          v-on:click="$router.go(-1)"
        >
        </v-btn>
      </v-row>

      <v-row justify="center" class="main_root">
        <v-card
          :class="$mq == 'sm' ? 'rounded-xl' : 'rounded-b-xl'"
          color="transparent"
          width="100%"
        >
          <v-row justify="center" class="main_title_1">
            <v-btn
              v-if="$mq === 'sm'"
              elevation="0"
              class="main_root_back"
              icon
              v-on:click="$router.go(-1)"
            >
            </v-btn>
            <v-col
              cols="12"
              class="main_title_1_text main_title_text_font_color"
            >
              {{ title }}
            </v-col>
          </v-row>
          <v-card
            elevation="0"
            class="d-flex flex-column align-center justify-center rounded-0"
            :color="$mq == 'sm' ? '#FFFFFFBB' : '#FFFFFF'"
          >
            <!-- list -->
            <v-container class="ma-0 item_list_box">
              <v-row dense class="thumblist">
                <v-col md="4" sm="6" v-for="(item, index) in list" :key="index">
                  <div
                    :class="
                      'item_div ' + $mq + ' ' + (item.idx == '' ? 'off' : '')
                    "
                    v-on:click="goto_view(item.idx)"
                    v-bind:style="{ background: item.color_code }"
                  >
                    <v-img
                      v-if="item.idx != ''"
                      v-bind:src="item.member_img_url"
                      :class="'gif_size ' + $mq"
                      cover
                    >
                    </v-img>
                    <div v-if="item.idx != ''" :class="'item_text ' + $mq">
                      <v-card-subtitle class="span_text"
                        ><span class="text-overflow"
                          >{{ item.title }}
                        </span></v-card-subtitle
                      >
                      <v-card-subtitle class="span_text"
                        ><span class="text-overflow">{{ item.name }}</span>
                      </v-card-subtitle>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-container>

            <!-- paging -->
            <v-card-actions class="fullwidth justify-center paging_bg">
              <v-card-actions class="justify-center pa-0">
                <v-btn icon class="size_arrow" v-on:click="first_prevPage()">
                  <v-icon class="size_arrow color_arrow">
                    mdi-chevron-double-left
                  </v-icon>
                </v-btn>
              </v-card-actions>
              <v-card-actions class="justify-center pa-0">
                <v-btn icon class="size_arrow" v-on:click="prevPage()">
                  <v-icon class="size_arrow color_arrow">
                    mdi-chevron-left
                  </v-icon>
                </v-btn>
              </v-card-actions>

              <v-card-actions
                class="justify-center paging_round"
                id="bt_s"
                v-for="n in arrs"
                :key="n"
              >
                <v-card-actions v-if="total_page >= n">
                  <v-btn
                    v-if="now_page == n"
                    class="paging_num paging_on"
                    depressed
                    fab
                    small
                    dark
                    v-on:click="nowData(n)"
                  >
                    {{ n }}
                  </v-btn>

                  <v-btn
                    v-else
                    class="paging_num paging_off"
                    outlined
                    depressed
                    fab
                    small
                    dark
                    v-on:click="nowData(n)"
                  >
                    {{ n }}
                  </v-btn>
                </v-card-actions>
              </v-card-actions>

              <v-card-actions class="justify-center pa-0">
                <v-btn
                  icon
                  class="size_arrow color_arrow"
                  v-on:click="nextPage()"
                >
                  <v-icon class="size_arrow color_arrow">
                    mdi-chevron-right
                  </v-icon>
                </v-btn>
              </v-card-actions>
              <v-card-actions class="justify-center pa-0">
                <v-btn icon class="size_arrow" v-on:click="last_nextPage()">
                  <v-icon class="size_arrow color_arrow">
                    mdi-chevron-double-right
                  </v-icon>
                </v-btn>
              </v-card-actions>
            </v-card-actions>
          </v-card>
          <v-btn
            class="rounded-0 rounded-b-xl button_3"
            v-on:click="$router.push('/after_use_write')"
          >
            {{ $ml.get("after_use_list_btn") }}
          </v-btn>
        </v-card>
      </v-row>
    </v-card>

    <!-- 상세 팝업 -->
    <v-card
      flat
      tile
      class="mx-auto"
      min-width="320"
      :max-width="$store.state.web_max_width"
      color="transparent"
      v-else
    >
      <v-row justify="center" class="title_root">
        <v-col cols="12" class="title_root_text">
          {{ $ml.get("main_button_05") }}
        </v-col>
        <div class="title_root_text_line"></div>
        <v-btn
          elevation="0"
          class="title_root_back"
          icon
          v-on:click="$router.go(-1)"
        >
        </v-btn>
      </v-row>

      <!-- PC -->
      <v-row
        v-if="$mq !== 'sm'"
        class="animation_top main_root"
        :class="[review_detail_popup ? 'start' : '', $mq]"
      >
        <v-card width="100%" class="rounded-b-xl">
          <v-img
            v-if="input_img_gubun != 'N' && $mq == 'sm'"
            v-bind:src="input_img_p"
            class="table_img"
            :style="'background-color:' + input_color_code"
            contain
          ></v-img>
          <v-row justify="center" class="ma-0">
            <v-row no-gutters class="justify-center">
              <v-row no-gutters class="justify-center pa-3">
                <div
                  class="table_title"
                  v-html="$ml.get('after_use_list_detail_title_1')"
                ></div>
                <div class="bar_right_line">|</div>
                <div class="table_text" v-html="input_name_p"></div>
              </v-row>
              <div class="bar_bottom_line fullwidth"></div>
              <v-row no-gutters class="justify-center pa-3">
                <div
                  class="table_title"
                  v-html="$ml.get('after_use_list_detail_title_2')"
                ></div>
                <div class="bar_right_line">|</div>
                <div class="table_text" v-html="input_title_p"></div>
              </v-row>
              <div class="bar_bottom_line fullwidth"></div>
              <v-row no-gutters class="justify-center pa-3 table_bg">
                <div
                  v-if="$mq != 'sm'"
                  class="table_title_long"
                  v-html="$ml.get('after_use_list_detail_title_3')"
                ></div>
                <div v-if="$mq != 'sm'" class="bar_right_line">
                  |
                </div>
                <v-col class="table_title_long_padding">
                  <v-row class="ma-0">
                    <v-img
                      v-if="input_img_gubun != 'N' && $mq != 'sm'"
                      v-bind:src="input_img_p"
                      class="table_img"
                      :style="'background-color:' + input_color_code"
                      contain
                    ></v-img>
                    <v-col class="table_text long" v-html="input_content_p">
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-row>
          </v-row>
          <v-btn
            class="rounded-0 rounded-b-xl button_1 userlist_button_color"
            v-on:click="$router.go(-1)"
          >
            {{ $ml.get("after_use_list_back_btn") }}
          </v-btn>
        </v-card>
      </v-row>

      <!-- Mobile -->
      <v-row
        v-else
        justify="center"
        class="animation_top main_root"
        :class="[review_detail_popup ? 'start' : '', $mq]"
      >
        <v-card
          width="100%"
          :class="$mq == 'sm' ? 'rounded-xl' : 'rounded-b-xl'"
          :color="$mq == 'sm' ? '#FFFFFFBB' : '#FFFFFF'"
        >
          <v-row justify="center" class="main_title_1">
            <v-btn
              v-if="$mq === 'sm'"
              elevation="0"
              class="main_root_back"
              icon
              v-on:click="$router.go(-1)"
            >
            </v-btn>
            <v-col v-if="$mq != 'sm'" cols="12" class="main_title_1_text">
              {{ $ml.get("after_use_list_new_title") }}
            </v-col>
            <v-col v-else cols="12" class="main_title_1_text">
              {{ $ml.get("after_use_write_m_title") }}
            </v-col>
          </v-row>
          <v-img
            v-if="input_img_gubun != 'N' && $mq == 'sm'"
            v-bind:src="input_img_p"
            class="table_img"
            :style="'background-color:' + input_color_code"
            contain
          ></v-img>
          <v-row justify="center" class="ma-0">
            <v-row no-gutters class="justify-center">
              <v-card
                width="100%"
                class="table_card_text pt-3 pb-3 ma-3 mb-1 rounded-lg"
              >
                <v-col class="table_text" v-html="input_name_p"></v-col>
              </v-card>

              <v-card width="100%" class="ma-3 mb-1 pt-3 pb-3 rounded-lg">
                <v-col class="table_text" v-html="input_title_p"></v-col>
              </v-card>

              <v-card width="100%" class="ma-3 mb-5 rounded-lg pt-3 pb-3">
                <v-row class="ma-0">
                  <v-img
                    v-if="input_img_gubun != 'N' && $mq !== 'sm'"
                    v-bind:src="input_img_p"
                    class="table_img"
                    contain
                  ></v-img>
                  <v-col
                    v-if="input_img_gubun != 'N'"
                    class="table_text long"
                    v-html="input_content_p"
                  >
                  </v-col>

                  <v-col
                    v-else
                    class="table_text long_new_type"
                    v-html="input_content_p"
                  >
                  </v-col>
                </v-row>
              </v-card>

              <!-- <v-row
                no-gutters
                class="justify-center table_bg"
                style="padding:0px;"
              >
                <div
                  v-if="$mq != 'sm'"
                  class="table_title_long"
                  
                  v-html="$ml.get('after_use_list_detail_title_3')"
                ></div>
                <div v-if="$mq != 'sm'" class="bar_right_line">
                  |
                </div>
                <v-col class="table_title_long_padding">
                  <v-row style="margin: 0px;">
                    <v-img
                      v-if="input_img_gubun != 'N' && $mq != 'sm'"
                      v-bind:src="input_img_p"
                      class="table_img"
                      
                      :style="'background-color:' + input_color_code"
                      contain
                    ></v-img>
                    <v-col
                      class="table_text long"
                      
                      v-html="input_content_p" 
                    >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row> -->
            </v-row>
          </v-row>
          <v-btn
            class="rounded-0 rounded-b-xl button_3"
            v-on:click="$router.go(-1)"
          >
            {{ $ml.get("after_use_list_back_btn") }}
          </v-btn>
        </v-card>
      </v-row>
    </v-card>
  </v-main>
</template>

<script>
export default {
  created() {
    this.nowData(1);

    //팝업 띄움
    var target = this.$route.fullPath.substring(
      this.$route.fullPath.indexOf("#")
    );
    if (target == "#review_detail") {
      this.$router.go(-1);
    } else if (target.indexOf("#") < 0) {
      this.review_detail_popup = false;
    }
  },

  data: () => {
    return {
      review_detail_popup: false,
      btNum_1: "1",
      btNum_2: "2",
      btNum_3: "3",
      btNum_4: "4",
      btNum_5: "5",
      arrs: [1, 2, 3, 4, 5],
      list: [],
      start_page2: 5,
      start_page: 0,
      end_page: 0,
      now_page: 0,
      pageSize: 1,
      total_page: 0
    };
  },
  computed: {
    title() {
      return this.$mq == "sm"
        ? this.$ml.get("after_use_write_m_title")
        : this.$ml.get("after_use_list_new_title");
    }
  },
  methods: {
    goto_view: function(idx) {
      //alert("B")
      if (idx == "") {
        return;
      }

      var index = this.list.findIndex(function(item) {
        return item.idx === idx;
      });

      this.input_name_p = this.list[index].name;
      this.input_img_p = this.list[index].member_img_url;
      this.input_img_gubun = this.list[index].img_gubun;
      this.input_title_p = this.list[index].title;
      this.input_content_p = this.list[index].content;
      this.input_color_code = "#e1e9c4";

      this.$router.push({ hash: "#review_detail" });
    },
    nextPage: function() {
      this.num = this.now_page + 1;
      if (this.total_page >= this.num) {
        if (this.end_page <= this.num) {
          this.start_page = this.num;
          this.end_page = this.start_page + 4;
          if (this.end_page >= this.total_page) {
            this.end_page = this.total_page;
          }
          this.nowData(this.num);
        } else {
          this.nowData(this.num);
        }
      }

      //alert(this.start_page);
    },
    last_nextPage: function() {
      this.nowData(this.total_page);
    },
    prevPage: function() {
      this.num = this.now_page - 1;
      if (this.num >= 1) {
        this.start_page = this.num;
        this.nowData(this.num);
      }
    },
    first_prevPage: function() {
      this.nowData(1);
    },
    nowData: function(num) {
      //api_review_list
      var self = this;
      var api = "api_review_list.php";
      var param = {
        page: num
      };

      self.$store.dispatch("apiCall", { api, param }).then(() => {
        //test123
        var data = self.$store.state.api_result;
        // var data = {
        //   ret_code: "S",
        //   list: [
        //     {
        //       idx: "374",
        //       member_idx: "36",
        //       member_img_url:
        //         "http://2000happyclick.com/api/review_img/11.gif",
        //       title:
        //         "\uc9c1\uc811 \uc0ac\uc6a9\ud574 \ubcf8 \ub9cc\uc871\uc2a4\ub7ec\uc6b4 \ud6c4\uae30^^",
        //       content:
        //         "\uc6b0\uc5f0\ud788 \uc9c0\uc778\uc744  \ud1b5\ud574 \ud589\ubcf5\uc194\ub8e8\uc158\uc774\ub77c\ub294 \ud504\ub85c\uadf8\ub7a8\uc744 \ub4e3\uac8c \ub3fc \uc0ac\uc6a9\ud574 \ubd24\uc5b4\uc694 *^^*\n\ucc98\uc74c\uc5d0\ub294 \uc2e0\uae30\ud558\uba74\uc11c\ub3c4 \ud6a8\uacfc\uac00 \uc788\uc744\uae4c ? \ud558\ub294 \uc0dd\uac01\uc774 \ub4e4\uc5c8\uc9c0\ub9cc  \ub9c9\uc0c1 \uc0c1\ub2f4 \uc2e0\uccad\ub3c4 \ud574\ubcf4\uace0 \ud558\ub2c8 \ub108\ubb34 \uce5c\uc808\ud558\uac8c \ub2f5\ubcc0\ub3c4 \ud574\uc8fc\uc2dc\ub354\ub77c\uace0\uc694 \u3160\u3160  \uc804\ud654\uc0c1\ub2f4\uae4c\uc9c0 \ud558\uace0 \ub9c8\uc74c\uc758 \uc548\uc815\ub3c4 \uc5bb\uace0 \ub354\ubd88\uc5b4 \ud0a4\ud2b8\uae4c\uc9c0 \uc8fc\uc2dc\ub2c8 \ub108\ubb34 \ub9cc\uc871\uc2a4\ub7ec\uc6cc\uc694~\n\uc55e\uc73c\ub85c\ub3c4 \uc885\uc885 \uc0ac\uc6a9\ud560\uac70\uc5d0\uc694~!",
        //       name: "\uc2e0\uc8fc\uc740",
        //       reg_date: "2021-11-05 13:09:10",
        //       color_code: "#fae29c",
        //       img_gubun: "N"
        //     },
        //     {
        //       idx: "373",
        //       member_idx: "297",
        //       member_img_url:
        //         "http://2000happyclick.com/api/review_img/9.gif",
        //       title: "\uc720\uc775\ud574\uc694^^~",
        //       content:
        //         "\uc0c1\ub2f4\ub0b4\uc6a9\uc774 \uc5ec\ub7ec\ubaa8\ub85c \ub9ce\uc740\ub3c4\uc6c0\uc774 \ub418\uc5c8\uc2b5\ub2c8\ub2e4. \uc774\ucc9c\uc2dc\ubbfc\uc5d0\uac8c \uc774\ub85c\uc6b4 \uc774\ub7ec\ud55c \uac83\ub4e4\uc774 \ub354\uc6b1 \ud64d\ubcf4\ub418\uc5c8\uc74c \uc88b\uaca0\ub124\uc694^^",
        //       name: "\uc720\ub2e4\uc601",
        //       reg_date: "2021-11-05 12:54:58",
        //       color_code: "#6a858c",
        //       img_gubun: "N"
        //     },
        //     {
        //       idx: "372",
        //       member_idx: "49",
        //       member_img_url:
        //         "https://happyclick.icheon.go.kr/api_new/review_img//thumbnail_wid_1636084377_0.img.jpg",
        //       title:
        //         "\uc774\ub7f0 \uac70 \ucc98\uc74c \uc0ac\uc6a9\ud574\ubcf4\ub294\ub370 \uc88b\ub124\uc694!",
        //       content:
        //         "\ub9c8\uc74c\uc774 \ubd88\ud3b8\ud55c\ub370 \uc774\ub7f0 \uc5b4\ud50c\ub85c \ub9c8\uc74c\uc774 \uc870\uae08 \ud3b8\ud574\uc9c4 \uac83 \uac19\uc2b5\ub2c8\ub2e4. \uc2dc\ubbfc\uc5d0\uac8c \uc815\ub9d0 \ud544\uc694\ud55c \uc11c\ube44\uc2a4\uc778 \uac83 \uac19\uc544\uc694!\n\uc694\uc998\uc5d0 \uc81c\uac00 \uc88b\uc544\ud558\ub294 \ub4f1\uc0b0\uc744 \uac14\ub2e4\uac00 \ucc0d\uc740 \uc0ac\uc9c4 \ucca8\ubd80\ud574\ubd05\ub2c8\ub2e4....",
        //       name: "\ucd5c\uc0c8\ud798",
        //       reg_date: "2021-11-05 12:52:57",
        //       color_code: "#ffefef",
        //       img_gubun: "Y"
        //     },
        //     {
        //       idx: "371",
        //       member_idx: "80",
        //       member_img_url:
        //         "http://2000happyclick.com/api_new/review_img//thumbnail_wid_1635832314_0.991625335E6089F302.jpeg",
        //       title: "\uc88b\uc2b5\ub2c8\ub2e4.",
        //       content:
        //         "\ubaa8\ubc14\uc77c\ub85c\ub3c4 \uc0ac\uc6a9\ud560 \uc218 \uc788\uc5b4\uc11c \uc88b\uc544\uc694.",
        //       name: "\uc774\uc694\uc149",
        //       reg_date: "2021-11-02 13:50:36",
        //       color_code: "#ffefef",
        //       img_gubun: "Y"
        //     },
        //     {
        //       idx: "370",
        //       member_idx: "80",
        //       member_img_url:
        //         "http://2000happyclick.com/api_new/review_img//thumbnail_wid_1635842553_0.993CFE4E5D64978514.jpeg",
        //       title: "\uac04\ud3b8\ud558\ub124\uc694",
        //       content:
        //         "\uac04\ub2e8\ud558\uac8c \ud14c\uc2a4\ud2b8 \ud574\ubcfc \uc218 \uc788\uace0 \uac04\ud3b8\ud574\uc11c \uc0ac\uc6a9\ud558\uae30 \uc88b\uc2b5\ub2c8\ub2e4",
        //       name: "\uc774\uc694\uc149",
        //       reg_date: "2021-11-02 12:35:36",
        //       color_code: "#ffefef",
        //       img_gubun: "Y"
        //     },
        //     {
        //       idx: "369",
        //       member_idx: "244",
        //       member_img_url:
        //         "https://happyclick.icheon.go.kr/api_new/review_img//thumbnail_wid_1635753091_0.img.jpg",
        //       title: "\ub108\ubb34 \ud3b8\ud55c \uac70 \uac19\uc544\uc694~",
        //       content:
        //         "\uc5b4\ub514\uc11c\ub098 \uc5b4\ud50c\uc744 \uc4f8 \uc218 \uc788\uc5b4\uc11c \uc815\ub9d0 \uc88b\ub124\uc694!\n\uc0c1\ub2f4 \ub2f5\ubcc0\ub3c4 \ube68\ub9ac \ud574\uc8fc\uc2dc\uace0~!\n\uacc4\uc18d \uc0c1\ub2f4 \ubc1b\uc544\ubcf4\uaca0\uc2b5\ub2c8\ub2e4",
        //       name: "\uc774\uc0c8\ubd04",
        //       reg_date: "2021-11-01 16:51:31",
        //       color_code: "#ffefef",
        //       img_gubun: "Y"
        //     }
        //   ],
        //   total_page_count: 2,
        //   ret_msg: "성공"
        // };
        if (data == false || data == "err") {
          //error
          alert(
            "정보를 가져오는데 실패하였습니다. 새로 고침 후 다시 시도해 주세요."
          );
        } else {
          try {
            if (data.ret_code == "S") {
              // alert("현재들어온 번호"+num);
              self.now_page = num;
              if (data.total_page_count <= 5) {
                self.start_page = 1;

                self.end_page = data.total_page_count;
              } else if (self.now_page <= 5) {
                self.arrs = [1, 2, 3, 4, 5];
              } else {
                var page_arr_sum =
                  self.now_page % 5 >= 1
                    ? parseInt(self.now_page / 5) * 5 + 5
                    : parseInt(self.now_page / 5) * 5;
                self.arrs = [
                  page_arr_sum - 4,
                  page_arr_sum - 3,
                  page_arr_sum - 2,
                  page_arr_sum - 1,
                  page_arr_sum
                ];
              }

              self.total_page = data.total_page_count;

              var temp = data.list.length % 6;
              if (temp != 0) {
                for (var i = 0; i < 6 - temp; i++) {
                  data.list.push({ idx: "" });
                }
              }

              self.list = data.list;
            } else {
              alert(
                "정보를 가져오는데 실패하였습니다. 새로 고침 후 다시 시도해 주세요."
              );
            }
          } catch (e) {
            alert(
              "정보를 가져오는데 실패하였습니다. 새로 고침 후 다시 시도해 주세요."
            );
          }
        }
      });
    }
  },
  watch: {
    $route(to) {
      //팝업 띄움
      var target = to.fullPath.substring(to.fullPath.indexOf("#"));
      if (target == "#review_detail") {
        this.review_detail_popup = true;
      } else if (target.indexOf("#") < 0) {
        this.review_detail_popup = false;
      }
    },
    $mq() {
      this.nowData(this.now_page);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/common";

.item_list_box {
  max-width: 996px;
  padding: 40px 20px 0px 20px !important;
}
.item_div {
  width: 272px;
  height: 210px;
  border-radius: 12px;
  margin-bottom: 30px;
  margin-left: calc(50% - 136px);
  cursor: pointer;
}

.item_div.off {
  background-color: transparent !important;
}

.item_text {
  padding: 10px 20px 10px 20px;
  width: 272px;
  height: 70px;
  background-color: rgba(0, 0, 0, 0.5);
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}

.span_text {
  height: 30px;
  padding: 0px;
  text-align: left;
  font: normal normal bold 19px/30px Noto;
  letter-spacing: 0px;
  color: #d0d0d0 !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.gif_size {
  width: 100%;
  height: 140px;
  background-position: center;
}

.paging_bg {
  width: 100%;
  padding: 15px 0px;
  // background-color: $bg-color-counsel_list-table-paging;
}

.paging_round {
  min-width: 72px;
}

.paging_num {
  width: 36px;
  height: 36px;
  font: normal normal 15px/35px Noto;
}

.paging_on {
  background-color: $bg-color-counsel-list-table-paging-btn-on !important;
  color: $ft-color-counsel-list-table-paging-btn-on;
}

.paging_off {
  border: 3px solid $bg-color-counsel-list-table-paging-btn-off-border;
  color: $ft-color-counsel-list-table-paging-btn-off;
}

.size_arrow {
  width: 36px !important;
  height: 36px !important;
  font-size: 36px !important;
}

.color_arrow {
  color: $ft-color-counsel_list-table-paging !important;
}

.table_title {
  width: 180px;
  height: 60px;
  font: normal normal bold 20px/60px Noto;
  color: $ft-color-counsel_list-table_title;
}

.table_title_long {
  width: 180px;
  height: 339px;
  font: normal normal bold 20px/339px Noto;
  color: $ft-color-counsel_list-table_title;
}

.table_text {
  text-align: left !important;
  padding: 0px 24px 0px 24px;
  width: calc(100% - 186px);
  font: normal normal normal 19px/34px Noto;
  color: $ft-color-counsel_list-table_title;
  display: flex;
  align-items: center;
  word-break: break-all;
}

.table_text.long {
  height: 299px;
  padding: 0px;
  overflow-x: hidden;
  overflow-y: scroll;
  align-items: normal;
}

.bar_right_line {
  width: 6px;
  font: normal normal 18px/22px Noto;
  color: $ft-color-counsel_list-table-line-right;
  display: flex;
  align-items: center;
}

.bar_bottom_line {
  background-color: $ft-color-counsel_list-table-line-bottom;
  background-position: top center;
  height: 1px;
}

.table_bg {
  background-color: $bg-color-counsel_list-table;
}

.table_img {
  // border: 1px solid #707070;
  margin-right: 20px;
  // height: 299px;
  // max-width: 369px;
  height: 250px;
  max-width: 300px;
}

.table_title_long_padding {
  padding: 40px 24px 0px 24px;
}

.item_text.sm .span_text {
  font: normal normal bold 3.6vw/5vw Noto;
  height: 5vw;
}

.bg_card {
  background-image: url("../assets/bg_review.png");
}

// v-image__image--contain
.v-image__image {
  background-color: red !important;
  background-position: left !important;
}

// .userlist_button_color {
//   background-color: $bg-color-user_list-back-btn !important;
// }

/* mobile */
.sm.bg_card {
  background-image: url("../assets/bg_review_m.png");

  .paging_round {
    min-width: 11vw;
  }

  .paging_num {
    width: 8vw;
    height: 8vw;
    font: normal normal bold 3.6vw/5vw Noto;
  }

  .size_arrow {
    width: 8vw !important;
    height: 8vw !important;
    font-size: 8vw !important;
  }

  .item_list_box {
    padding: 3vw !important;
  }

  .item_div {
    padding-right: 0px;
    margin-right: 0px;
    width: 40vw;
    height: 33vw;
    border-radius: 20px;
    margin: auto;
  }

  .item_text {
    padding: 1vw 3vw 1vw 3vw;
    width: 100%;
    height: 40%;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  .table_img {
    width: 100%;
    height: 50vw;
    max-width: none;
  }

  .table_title {
    width: 16%;
    height: 11vw;
    font: normal normal bold 3.8vw/6vw Noto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .bar_right_line {
    font: normal normal bold 3.8vw/6vw Noto;
  }

  .table_text {
    width: calc(84% - 6px);
    padding: 0px 4%;
    font: normal normal bold 3.8vw/6vw Noto;
  }

  .table_text.long {
    height: 40vw;
  }

  .table_text.long_new_type {
    height: 80vw;
    // padding: 15px;
    overflow-x: hidden;
    overflow-y: scroll;
    align-items: normal;
  }

  .long_new_type {
    height: 60vw;
  }

  .table_title_long_padding {
    padding: 4%;
  }

  .common_btn {
    height: 50px;
  }

  .paging_off {
    border: 3px solid $bg-color-counsel-list-table-paging-btn-off-border;
    color: $ft-color-counsel-list-table-paging-btn-off;
  }

  .gif_size {
    width: 100%;
    height: 60%;
    margin-left: 0px;
  }
}
</style>
