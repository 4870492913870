<template>
  <ion-page>
    <ion-content fullscreen scroll-y="true">
      <v-main class="fullwidth fullheight bg_card" :class="$mq">
        <!-- PC 웹 & 모바일 -->
        <v-card
          flat
          tile
          class="mx-auto"
          min-width="320"
          :max-width="$store.state.web_max_width"
          color="transparent"
        >
          <v-row justify="center" class="title_root">
            <v-col cols="12" class="title_root_text">
              {{ $ml.get("main_button_02") }}
            </v-col>
            <div class="title_root_text_line"></div>
            <v-btn
              elevation="0"
              class="title_root_back"
              icon
              v-on:click="$router.go(-1)"
            >
            </v-btn>
          </v-row>

          <v-row justify="center" class="main_root">
            <v-card
              :class="$mq == 'sm' ? 'rounded-xl' : 'rounded-b-xl'"
              color="transparent"
              width="100%"
            >
              <v-row justify="center" class="main_title_1">
                <v-btn
                  elevation="0"
                  class="main_root_back"
                  icon
                  v-on:click="$router.go(-1)"
                >
                </v-btn>
                <v-col
                  cols="12"
                  class="main_title_1_text main_title_text_font_color"
                >
                  {{ item.title }}
                </v-col>
              </v-row>

              <v-card
                elevation="0"
                min-height="250"
                class="d-flex align-center justify-center rounded-0 info_text_0"
                :color="$mq == 'sm' ? '#FFFFFFBB' : '#FFFFFF'"
                v-html="item.detail"
              >
              </v-card>
              <v-card
                elevation="0"
                class="d-flex justify-center rounded-0 info_text_1"
                :color="$mq == 'sm' ? '#FFFFFFBB' : '#FFFFFF'"
                v-html="item.subtitle"
              >
              </v-card>
              <v-btn class="rounded-0 rounded-b-xl button_1" v-on:click="go()">
                {{ $ml.get("d_s_info_btn") }}
              </v-btn>
            </v-card>
          </v-row>
        </v-card>
      </v-main>
    </ion-content>
  </ion-page>
</template>

<script>
export default {
  data: function() {
    return {
      item: {
        idx: "",
        title: "",
        list: [],
        survey_type: "",
        member_img_url: "",
        detail: "",
        img: "",
        subtitle: ""
      }
    };
  },
  computed: {},
  methods: {
    go: function() {
      //텍스트 설문으로
      this.$router.push("/d_text");
    }
  },
  created() {
    this.item = this.$store.state.diagnosis_data;
  },
  watch: {}
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/common";

.bg_card {
  background-image: url("../assets/bg_self.png");
}

/* mobile */
.sm.bg_card {
  background-image: url("../assets/bg_self_m.png");
}
</style>
