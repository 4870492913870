<template>
  <v-main class="fullwidth fullheight bg_card" :class="$mq">
    <!-- PC 웹 & 모바일 -->
    <v-card
      flat
      tile
      class="mx-auto"
      min-width="320"
      :max-width="$store.state.web_max_width"
      color="transparent"
    >
      <v-row justify="center" class="title_root">
        <v-col cols="12" class="title_root_text">
          {{ $ml.get("find_pw_root") }}
        </v-col>
        <div class="title_root_text_line"></div>
        <v-btn
          elevation="0"
          class="title_root_back"
          icon
          v-on:click="$router.go(-1)"
        >
        </v-btn>
      </v-row>

      <!-- tel check -->
      <v-row v-if="!check_success" justify="center" class="main_root">
        <v-card
          :class="$mq == 'sm' ? 'rounded-xl' : ''"
          color="transparent"
          width="100%"
        >
          <v-row justify="center" class="main_title_1">
            <v-btn
              elevation="0"
              class="main_root_back"
              icon
              v-on:click="$router.go(-1)"
            >
            </v-btn>
            <v-col cols="12" class="main_title_1_text">
              {{ title }}
            </v-col>
          </v-row>

          <v-card
            elevation="0"
            min-height="300"
            class="d-flex flex-column align-center justify-center rounded-0 main_title_1_text"
            :class="$mq == 'sm' && !sended ? 'rounded-b-xl' : ''"
            :color="$mq == 'sm' ? '#FFFFFFBB' : '#00000066'"
          >
            <v-card
              v-if="$mq == 'sm'"
              elevation="0"
              min-height="30vw"
              width="70vw"
              color="transparent"
              class="d-flex align-center justify-center main_title_1_text"
            >
              {{ $ml.get("find_pw_title_1") }}
            </v-card>
            <v-form ref="form_tel" class="d-flex find_root">
              <v-text-field
                class="find_pw_input"
                :class="$mq == 'sm' ? 'rounded-lg' : 'rounded-lg'"
                solo
                :height="$mq == 'sm' ? '16vw' : 58"
                :placeholder="$ml.get('counsel_login_tel_label')"
                persistent-hint
                :rules="tel_rules"
                :value="input_tel"
                @change="v => (input_tel = v)"
                :disabled="sended"
                required
              ></v-text-field>
              <v-btn
                :disabled="sended"
                class="find_pw_btn"
                :class="$mq == 'sm' ? 'rounded-lg' : 'rounded-lg'"
                @click="send()"
              >
                {{ $ml.get("find_pw_btn_1") }}
              </v-btn>
            </v-form>
            <div v-if="sended" class="ma-0 mt-n3 d-flex find_root">
              <v-text-field
                class="find_pw_input"
                :class="$mq == 'sm' ? 'rounded-lg' : 'rounded-lg'"
                solo
                :height="$mq == 'sm' ? '16vw' : 58"
                :placeholder="$ml.get('find_pw_check_lable')"
                persistent-hint
                :value="input_check"
                @change="v => (input_check = v)"
              ></v-text-field>
              <v-btn
                v-if="$mq != 'sm'"
                class="rounded-lg find_pw_btn white"
                @click="check()"
              >
                {{ $ml.get("find_pw_btn_2") }}
              </v-btn>
            </div>

            <div class="mt-n5 find_pw_text" v-html="info_text"></div>
          </v-card>
          <v-btn
            v-if="$mq == 'sm' && sended"
            class="rounded-0 rounded-b-xl button_3"
            v-on:click="check()"
          >
            {{ $ml.get("find_pw_btn_2") }}
          </v-btn>
        </v-card>
      </v-row>

      <!-- change pw -->
      <v-row v-else justify="center" class="main_root">
        <v-card
          :class="$mq == 'sm' ? 'rounded-xl' : 'rounded-b-xl'"
          color="transparent"
          width="100%"
        >
          <v-row justify="center" class="main_title_1">
            <v-btn
              elevation="0"
              class="main_root_back"
              icon
              v-on:click="$router.go(-1)"
            >
            </v-btn>
            <v-col cols="12" class="main_title_1_text">
              {{ title_2 }}
            </v-col>
          </v-row>

          <v-card
            elevation="0"
            min-height="300"
            class="d-flex flex-column align-center justify-center rounded-0 main_title_1_text"
            :color="$mq == 'sm' ? '#FFFFFFBB' : '#00000066'"
          >
            <v-card
              v-if="$mq == 'sm'"
              elevation="0"
              min-height="30vw"
              width="70vw"
              color="transparent"
              class="d-flex align-center justify-center main_title_1_text"
            >
              {{ $ml.get("find_pw_title_2") }}
            </v-card>
            <v-form ref="form_pw" class="d-flex flex-column find_root">
              <v-text-field
                class="find_pw_input"
                :class="$mq == 'sm' ? 'rounded-lg' : 'rounded-lg'"
                solo
                :height="$mq == 'sm' ? '16vw' : 58"
                :append-icon="show_pw ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show_pw ? 'text' : 'password'"
                @click:append="show_pw = !show_pw"
                :placeholder="$ml.get('counsel_login_pw_label')"
                persistent-hint
                :rules="pw_rules"
                :value="input_pw"
                @change="v => (input_pw = v)"
                required
              ></v-text-field>

              <v-text-field
                class="find_pw_input"
                :class="$mq == 'sm' ? 'rounded-lg' : 'rounded-lg'"
                solo
                :height="$mq == 'sm' ? '16vw' : 58"
                :append-icon="show_pw_check ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show_pw_check ? 'text' : 'password'"
                @click:append="show_pw_check = !show_pw_check"
                :placeholder="$ml.get('counsel_login_pw_check_label')"
                persistent-hint
                :rules="pw_check_rules"
                :value="input_pw_check"
                @change="v => (input_pw_check = v)"
                required
              ></v-text-field>
            </v-form>
            <div
              class="find_pw_text"
              v-html="$ml.get('find_pw_change_info')"
            ></div>
          </v-card>
          <v-btn
            class="rounded-0 rounded-b-xl button_3"
            v-on:click="pw_change()"
          >
            {{ $ml.get("find_pw_change_btn") }}
          </v-btn>
        </v-card>
      </v-row>
    </v-card>
  </v-main>
</template>

<script>
import { regexCheck } from "../common";

export default {
  data: function() {
    return {
      input_tel: "",
      sended: false,
      timer: null,
      max_time: 60,
      now_time: 0,
      interceptor: 0,
      input_check: "",
      info_text: "",
      check_success: false,
      show_pw: false,
      input_pw: "",
      show_pw_check: false,
      input_pw_check: "",
      tel_rules: [
        value => {
          return (
            regexCheck(1, value) || this.$ml.get("counsel_login_tel_rules_1")
          );
        },
        value =>
          (value || "").length == 11 ||
          this.$ml.get("counsel_login_tel_rules_2")
      ],
      pw_rules: [
        value => {
          return (
            regexCheck(1, value) || this.$ml.get("counsel_login_pw_rules_1")
          );
        },
        v => v.length == 4 || this.$ml.get("counsel_login_pw_rules_2")
      ],
      pw_check_rules: [
        value => {
          return (
            value == this.input_pw ||
            this.$ml.get("counsel_login_pw_check_rules_1")
          );
        }
      ]
    };
  },
  computed: {
    title() {
      return this.$mq == "sm"
        ? this.$ml.get("main_button_03")
        : this.$ml.get("find_pw_title_1");
    },
    title_2() {
      return this.$mq == "sm"
        ? this.$ml.get("main_button_03")
        : this.$ml.get("find_pw_title_2");
    }
  },
  methods: {
    send: function() {
      if (this.$refs.form_tel.validate()) {
        //api_member_password_search
        var self = this;
        var api = "api_member_password_search.php";
        var param = {
          tel: this.input_tel
        };

        self.$store.dispatch("apiCall", { api, param }).then(() => {
          var data = self.$store.state.api_result;

          if (data == false || data == "err") {
            // error
            alert(
              "정보를 가져오는데 실패하였습니다. 새로 고침 후 다시 시도해 주세요."
            );
          } else {
            if (data.ret_code == "S") {
              //web
              if (self.$mq != "sm") {
                const title = self.$ml.get("find_pw_root");
                const info = self.$ml.get("find_pw_alert_send_info");
                self.$store.commit("alert_show", { title, info });

                self.now_time = self.max_time;
              } else {
                self.info_text = self.$ml.get("find_pw_alert_send_info");
                self.interceptor = 3;
                self.now_time = self.max_time + 3;
              }

              self.sended = true;
              self.timer = setInterval(function() {
                if (self.interceptor-- <= 0) {
                  self.info_text =
                    self.now_time + self.$ml.get("find_pw_timer_text");
                }
                self.now_time--;
                if (self.now_time < 0) {
                  clearInterval(self.timer);
                  self.sended = false;
                  self.input_check = "";
                  self.info_text = "";
                }
              }, 1000);
            } else {
              alert(data.ret_msg);
            }
          }
        });
      }
    },
    check: function() {
      //api_member_check
      var self = this;
      var api = "api_member_check.php";
      var param = {
        tel: this.input_tel,
        num: this.input_check
      };

      self.$store.dispatch("apiCall", { api, param }).then(() => {
        var data = self.$store.state.api_result;

        if (data == false || data == "err") {
          // error
          alert(
            "정보를 가져오는데 실패하였습니다. 새로 고침 후 다시 시도해 주세요."
          );
        } else {
          if (data.ret_code == "S") {
            //맞을때
            //비밀번호 변경 화면으로 이동
            self.check_success = true;
            setTimeout(function() {
              self.$refs.form_pw.reset();
            }, 100);
          } else {
            //틀릴때
            self.info_text = self.$ml.get("find_pw_check_error_1");
            self.interceptor = 3;
          }
        }
      });
    },
    pw_change: function() {
      if (this.$refs.form_pw.validate()) {
        //api_member_password_edit
        var self = this;
        var api = "api_member_password_edit.php";
        var param = {
          tel: this.input_tel,
          num: this.input_check,
          password: this.input_pw
        };

        self.$store.dispatch("apiCall", { api, param }).then(() => {
          var data = self.$store.state.api_result;

          if (data == false || data == "err") {
            // error
            alert(
              "정보를 가져오는데 실패하였습니다. 새로 고침 후 다시 시도해 주세요."
            );
          } else {
            if (data.ret_code == "S") {
              const title = self.$ml.get("find_pw_root");
              const info = self.$ml.get("find_pw_alert_change_success");
              const fn = function() {
                self.$router.push("/");
              };
              self.$store.commit("alert_show", { title, info, fn });
            } else {
              alert(data.ret_msg);
            }
          }
        });
      }
    }
  },
  created() {},
  watch: {}
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/common";

.bg_card {
  background-image: url("../assets/bg_login.png");
}

.find_root {
  width: 420px;
}

.find_pw_input {
  font: normal normal normal 18px/24px Noto !important;
  letter-spacing: 0px;
}

.find_pw_btn {
  height: 58px !important;
  width: 150px;
  background-color: $bg-color-find_pw_btn !important;
  color: $ft-color-find_pw_btn !important;
  font: normal normal normal 18px/24px Maple !important;
  letter-spacing: 0px;
  margin: 0px 0px 0px 20px;
}

.find_pw_btn.white {
  background-color: $bg-color-find_pw_btn_white !important;
  color: $ft-color-find_pw_btn_white !important;
}

.find_pw_text {
  width: 420px;
  height: 52px;
  text-align: left;
  color: $ft-color-find_pw_text !important;
  font: normal normal normal 16px/20px Noto !important;
  letter-spacing: 0px;
}

.sm.bg_card {
  background-image: url("../assets/bg_login_m.png");

  .find_root {
    width: 84vw;
  }

  .find_pw_input {
    font: normal normal normal 4.6vw/6vw Noto !important;
  }

  .find_pw_btn {
    height: 16vw !important;
    width: 30vw;
    font: normal normal normal 4.6vw/6vw Maple !important;
    margin: 0px 0px 0px 3vw;
  }

  .find_pw_text {
    position: relative;
    top: 0px;
    width: 100%;
    height: 30vw;
    padding: 0px 4vw;
    font: normal normal normal 4.6vw/6vw Noto !important;
    color: $ft-color-counsel_login-find-pw-m !important;
  }
}
</style>
