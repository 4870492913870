<template>
  <v-main class="fullwidth fullheight bg_card" :class="$mq">
    <!-- PC 웹 & 모바일 -->
    <v-card
      flat
      tile
      class="mx-auto"
      min-width="320"
      :max-width="$store.state.web_max_width"
      color="transparent"
    >
      <v-row justify="center" class="title_root">
        <v-col cols="12" class="title_root_text title_root_text_another_color">
          {{ $ml.get("main_button_01") }}
        </v-col>
        <div class="title_root_text_line title_root_text_line_new_color"></div>
        <v-btn
          elevation="0"
          class="title_root_back"
          icon
          v-on:click="$router.go(-1)"
        >
        </v-btn>
      </v-row>

      <v-row justify="center" class="main_root">
        <v-card
          :class="$mq == 'sm' ? 'rounded-xl' : 'rounded-b-xl'"
          color="transparent"
          width="100%"
        >
          <v-row justify="center" class="main_title_1">
            <v-btn
              elevation="0"
              class="main_root_back"
              icon
              v-on:click="$router.go(-1)"
            >
            </v-btn>
            <v-col
              v-if="$mq != 'sm'"
              cols="12"
              class="main_title_1_text font_black"
            >
              <span>{{ $ml.get("info_title_01") }}</span>
              <!-- <img
                class="img_text"
                aspect-ratio="1"
                src="@/assets/ci_x_header.png"
              /> -->

              <span>{{ $ml.get("info_title_02") }}</span>
            </v-col>
            <v-col
              v-else
              cols="12"
              class="d-flex flex-column align-center main_title_1_text"
            >
              <span>{{ $ml.get("main_button_01") }}</span>
              <div class="title_root_text_line_m"></div>
            </v-col>
          </v-row>
          <v-row
            v-if="$mq == 'sm'"
            justify="center"
            class="main_title_1 sub info_title_sub"
          >
            <v-col cols="12" class="main_title_1_text">
              <span>{{ $ml.get("info_title_01") }}</span>
              <!-- <img
                class="img_text"
                aspect-ratio="1"
                src="@/assets/m_logo_b.png"
              /> -->
              <span>{{ $ml.get("info_title_02") }}</span>
            </v-col>
          </v-row>
          <v-card
            elevation="0"
            class="d-flex align-center justify-center rounded-0 rounded-b-xl pa-0 content"
          >
            <v-img
              :src="info_img"
              style="z-index: 3;"
              class="d-flex align-center justify-center rounded-0 rounded-b-xl pa-0 content"
              contain
            >
            </v-img>
          </v-card>
        </v-card>
      </v-row>
    </v-card>
  </v-main>
</template>

<script>
export default {
  data: function() {
    return {};
  },
  computed: {
    title() {
      return this.$mq == "sm"
        ? this.$ml.get("main_button_01")
        : this.$ml.get("info_title");
    },
    content() {
      return this.$mq == "sm"
        ? this.$ml.get("info_content_m")
        : this.$ml.get("info_content");
    },
    info_img() {
      return this.$mq == "sm"
        ? require("@/assets/info_m.png")
        : require("@/assets/info.png");
    }
  },
  methods: {},
  created() {},
  watch: {}
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/common";

.bg_card {
  background-image: url("../assets/bg_info.png");
}

.img_text {
  width: 60px;
  margin: 0px 0px -8px 2px;
}

.content {
  text-align: left;
  font: normal normal normal 18px/30px Noto;
  letter-spacing: 0px;
  color: $ft-color-title;
  padding: 40px;
}

.info_title_sub {
  background-color: $bg-color-Info-title-m-sub !important;
}

.title_root_text_another_color {
  color: $ft-color-title-root-color !important;
}

.title_root_text_line_new_color {
  border-top: solid 6px $ft-color-title-white;
}

/* mobile */
.sm.bg_card {
  background-image: url("../assets/bg_info_m.png");

  .main_title_1_text {
    position: relative;
  }

  .title_root_text_line_m {
    position: absolute;
    bottom: 0px;
    border-top: solid 3px $ft-color-title-white;
    height: 3px;
    width: 5vw;
  }

  .img_text {
    width: 12vw;
    margin: 0px 0px -1.2vw 2px;
  }

  .content {
    font: normal normal normal 4vw/6vw Noto;
  }
}
</style>
