var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',{staticClass:"fullwidth bg_card",class:_vm.$mq},[_c('InitDialog'),_c('div',{staticClass:"pa-0 ma-0 fullwidth fullheight"},[(_vm.$mq === 'sm')?_c('span',{staticClass:"main_bg_logo"}):_vm._e(),_c('v-card',{staticClass:"mx-auto d-flex align-end",attrs:{"flat":"","tile":"","height":"calc(100vh - 100px)","min-height":_vm.$mq == 'sm' ? 568 : 700,"width":1200,"color":"transparent"}},[_c('v-row',{staticClass:"main_button_root",attrs:{"justify":"center"}},[_c('v-row',{class:_vm.$mq !== 'sm' ? 'pt-5' : '',attrs:{"no-gutters":"","justify":"center"}},[_c('v-col',{attrs:{"cols":_vm.$mq == 'sm' ? 6 : 0},on:{"click":function($event){return _vm.goto('info')}}},[_c('v-hover',{staticClass:"main_button",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"button_bg bg_1",class:{ 'on-hover_1': hover },attrs:{"elevation":"0"}},[_c('div',{staticClass:"hover_effect"})])]}}])})],1),_c('v-col',{attrs:{"cols":_vm.$mq == 'sm' ? 6 : 0},on:{"click":function($event){return _vm.goto('d_start')}}},[_c('v-hover',{staticClass:"main_button",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"button_bg bg_2",class:{ 'on-hover_2': hover },attrs:{"elevation":"0"}},[_c('div',{staticClass:"hover_effect"})])]}}])})],1),_c('v-col',{attrs:{"cols":_vm.$mq == 'sm' ? 6 : 0},on:{"click":function($event){_vm.$mq === 'sm' ? _vm.goto('contents') : _vm.goto('counsel_intro')}}},[_c('v-hover',{staticClass:"main_button",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"button_bg bg_3",class:{ 'on-hover_3': hover },attrs:{"elevation":"0"}},[_c('div',{staticClass:"hover_effect"}),(_vm.$store.state.counseling_read == 'Y')?_c('v-chip',{staticClass:"main_btn_count rounded-xl justify-center"},[_vm._v(" 1 ")]):_vm._e()],1)]}}])})],1),_c('v-col',{attrs:{"cols":_vm.$mq == 'sm' ? 6 : 0},on:{"click":function($event){_vm.$mq == 'sm' ? _vm.goto('counsel_intro') : _vm.goto('contents')}}},[_c('v-hover',{staticClass:"main_button",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"button_bg bg_4",class:{ 'on-hover_4': hover },attrs:{"elevation":"0"}},[_c('div',{staticClass:"hover_effect"})])]}}])})],1),(_vm.$mq != 'sm')?_c('v-col',{on:{"click":function($event){return _vm.goto('after_use_list')}}},[_c('v-hover',{staticClass:"main_button",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"button_bg bg_5",class:{ 'on-hover_5': hover },attrs:{"elevation":"0"}},[_c('div',{staticClass:"hover_effect"})])]}}],null,false,3724221790)})],1):_vm._e(),(_vm.$mq != 'sm')?_c('v-col',{on:{"click":function($event){return _vm.go_home_page()}}},[_c('v-hover',{staticClass:"main_button",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"button_bg bg_6",class:{ 'on-hover_6': hover },attrs:{"elevation":"0"}},[_c('div',{staticClass:"hover_effect"})])]}}],null,false,2939366782)})],1):_vm._e()],1)],1)],1),_c('div',{staticClass:"bottom_background d-flex align-center justify-center"}),_c('div',{staticClass:"bottom_line"}),_c('div',{staticClass:"d-flex align-center justify-center footer_root",domProps:{"innerHTML":_vm._s(_vm.footer)}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }