<template>
  <v-main class="fullwidth fullheight bg_card" :class="$mq">
    <!-- PC 웹 & 모바일 -->
    <v-card
      flat
      tile
      class="mx-auto"
      min-width="320"
      :max-width="$store.state.web_max_width"
      color="transparent"
      v-if="!review_detail_popup"
    >
      <v-row
        class="d-flex justify-center bg_transparent"
        :class="$mq == 'sm' ? 'main_title_1' : 'title_root'"
      >
        <v-col
          cols="12"
          class="font_black"
          :class="$mq == 'sm' ? 'main_title_1_text' : 'title_root_text'"
        >
          {{ $ml.get("main_button_03") }}
        </v-col>
        <div class="title_root_text_line font_black"></div>
        <v-btn
          elevation="0"
          class="font_black"
          :class="$mq == 'sm' ? 'main_root_back' : 'title_root_back'"
          icon
          v-on:click="$router.go(-1)"
        >
        </v-btn>
      </v-row>

      <v-row class="main_root justify-center">
        <v-btn
          v-if="$store.state.user_info.kit_gubun == 'Y'"
          class="rounded-xl intro_btn"
          :class="$mq == 'sm' ? 'mb-4' : 'mb-12'"
          content_tap_btn_bg
          v-on:click="$router.push('/counsel_start').catch(() => {})"
        >
          {{ $ml.get("counsel_intro_btn_1") }}
        </v-btn>
        <v-img v-if="$mq !== 'sm'" src="@/assets/challenge.jpg"> </v-img>
        <v-img v-else src="@/assets/5senses_1.jpg"> </v-img>
        <v-card
          elevation="0"
          class="d-flex flex-column align-center justify-center rounded-b-xl list_bg"
        >
          <v-row
            class="ma-0 mt-5 item_list_box justify-left"
            :class="$mq == 'sm' ? '' : 'fullwidth'"
          >
            <v-img
              class="ml-3"
              :max-width="$mq == 'sm' ? '60vw' : '400'"
              src="@/assets/counsel_intro_list_text.png"
            ></v-img>
          </v-row>
          <!-- list -->
          <v-container class="ma-0 item_list_box">
            <v-row dense class="thumblist">
              <v-col md="4" sm="6" v-for="(item, index) in list" :key="index">
                <div
                  class="rounded-lg"
                  :class="
                    'item_div ' + $mq + ' ' + (item.idx == '' ? 'off' : '')
                  "
                  v-on:click="goto_view(item.idx)"
                >
                  <v-img
                    v-if="item.idx != ''"
                    v-bind:src="item.member_img_url"
                    :class="'gif_size ' + $mq"
                    class="rounded-t-lg"
                    cover
                  >
                  </v-img>
                  <div
                    v-if="item.idx != ''"
                    class="rounded-b-lg"
                    :class="'item_text ' + $mq"
                  >
                    <v-card-subtitle class="span_text"
                      ><span class="text-overflow"
                        >{{ item.content }}
                      </span></v-card-subtitle
                    >
                    <v-card-subtitle class="span_text"
                      ><span class="text-overflow"></span>
                    </v-card-subtitle>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>

          <!-- paging -->
          <v-card-actions class="fullwidth justify-center">
            <v-card-actions class="justify-center pa-0">
              <v-btn icon class="size_arrow" v-on:click="first_prevPage()">
                <v-icon class="size_arrow color_arrow">
                  mdi-chevron-double-left
                </v-icon>
              </v-btn>
            </v-card-actions>
            <v-card-actions class="justify-center pa-0">
              <v-btn icon class="size_arrow" v-on:click="prevPage()">
                <v-icon class="size_arrow color_arrow">
                  mdi-chevron-left
                </v-icon>
              </v-btn>
            </v-card-actions>

            <v-card-actions
              class="justify-center paging_round"
              id="bt_s"
              v-for="n in arrs"
              :key="n"
            >
              <v-card-actions v-if="total_page >= n">
                <v-btn
                  v-if="now_page == n"
                  class="paging_num paging_on"
                  depressed
                  fab
                  small
                  dark
                  v-on:click="nowData(n)"
                >
                  {{ n }}
                </v-btn>

                <v-btn
                  v-else
                  class="paging_num paging_off"
                  outlined
                  depressed
                  fab
                  small
                  dark
                  v-on:click="nowData(n)"
                >
                  {{ n }}
                </v-btn>
              </v-card-actions>
            </v-card-actions>

            <v-card-actions class="justify-center pa-0">
              <v-btn
                icon
                class="size_arrow color_arrow"
                v-on:click="nextPage()"
              >
                <v-icon class="size_arrow color_arrow">
                  mdi-chevron-right
                </v-icon>
              </v-btn>
            </v-card-actions>
            <v-card-actions class="justify-center pa-0">
              <v-btn icon class="size_arrow" v-on:click="last_nextPage()">
                <v-icon class="size_arrow color_arrow">
                  mdi-chevron-double-right
                </v-icon>
              </v-btn>
            </v-card-actions>
          </v-card-actions>
        </v-card>
      </v-row>
    </v-card>
    <!-- 상세 팝업 -->
    <v-card
      flat
      tile
      class="mx-auto"
      min-width="320"
      :max-width="$store.state.web_max_width"
      color="transparent"
      v-else
    >
      <v-row class="title_root justify-center">
        <v-col cols="12" class="title_root_text font_black">
          {{ $ml.get("main_button_03") }}
        </v-col>
        <div class="title_root_text_line font_black"></div>
        <v-btn
          elevation="0"
          class="title_root_back font_black"
          icon
          v-on:click="$router.go(-1)"
        >
        </v-btn>
      </v-row>

      <!-- PC -->
      <v-row
        v-if="$mq !== 'sm'"
        class="animation_top main_root justify-center"
        :class="[review_detail_popup ? 'start' : '', $mq]"
      >
        <v-card width="100%" class="rounded-b-xl">
          <v-img
            v-if="input_img_gubun != 'N' && $mq == 'sm'"
            v-bind:src="input_img_p"
            class="table_img"
            :style="'background-color:' + input_color_code"
            contain
          ></v-img>
          <v-row class="ma-0 justify-center">
            <v-row no-gutters class="justify-center">
              <div class="bar_bottom_line fullwidth"></div>
              <v-row no-gutters class="justify-center pa-0">
                <div
                  class="table_title"
                  v-html="$ml.get('after_use_list_detail_title_2')"
                ></div>
                <div class="bar_right_line">|</div>
                <div class="table_text" v-html="input_title_p"></div>
              </v-row>
              <div class="bar_bottom_line fullwidth"></div>
              <v-row no-gutters class="justify-center pa-0 table_bg">
                <div
                  v-if="$mq != 'sm'"
                  class="table_title_long"
                  v-html="$ml.get('after_use_list_detail_title_3')"
                ></div>
                <div v-if="$mq != 'sm'" class="bar_right_line">
                  |
                </div>
                <v-col class="table_title_long_padding">
                  <v-row class="ma-0">
                    <v-img
                      v-if="input_img_gubun != 'N' && $mq != 'sm'"
                      v-bind:src="input_img_p"
                      class="table_img"
                      :style="'background-color:' + input_color_code"
                      contain
                    ></v-img>
                    <v-col class="table_text long" v-html="input_content_p">
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-row>
          </v-row>
          <v-btn
            class="rounded-0 rounded-b-xl button_1"
            v-on:click="$router.go(-1)"
          >
            {{ $ml.get("after_use_list_back_btn") }}
          </v-btn>
        </v-card>
      </v-row>

      <!-- Mobile -->
      <v-row
        v-else
        class="animation_top main_root justify-center"
        :class="[review_detail_popup ? 'start' : '', $mq]"
      >
        <v-card
          width="100%"
          :class="$mq == 'sm' ? 'rounded-xl' : 'rounded-b-xl'"
          :color="$mq == 'sm' ? '#FFFFFFBB' : '#FFFFFF'"
        >
          <v-row class="main_title_1 justify-center">
            <v-btn
              v-if="$mq === 'sm'"
              elevation="0"
              class="main_root_back"
              icon
              v-on:click="$router.go(-1)"
            >
            </v-btn>
            <v-col cols="12" class="main_title_1_text">
              {{ $ml.get("main_button_03") }}
            </v-col>
          </v-row>
          <v-img
            v-if="input_img_gubun != 'N' && $mq == 'sm'"
            v-bind:src="input_img_p"
            class="table_img"
            :style="'background-color:' + input_color_code"
            contain
          ></v-img>
          <v-row class="ma-0 justify-center">
            <v-row no-gutters class="justify-center">
              <v-card width="100%" class="ma-3 mb-1 pt-3 pb-3 rounded-lg">
                <v-col class="table_text" v-html="input_title_p"></v-col>
              </v-card>

              <v-card width="100%" class="ma-3 mb-5 rounded-lg pt-3 pb-3">
                <v-row class="ma-0">
                  <v-img
                    v-if="input_img_gubun != 'N' && $mq !== 'sm'"
                    v-bind:src="input_img_p"
                    class="table_img"
                    contain
                  ></v-img>
                  <v-col
                    v-if="input_img_gubun != 'N'"
                    class="table_text long"
                    v-html="input_content_p"
                  >
                  </v-col>

                  <v-col
                    v-else
                    class="table_text long_new_type"
                    v-html="input_content_p"
                  >
                  </v-col>
                </v-row>
              </v-card>
            </v-row>
          </v-row>
          <v-btn
            class="rounded-0 rounded-b-xl button_3"
            v-on:click="$router.go(-1)"
          >
            {{ $ml.get("after_use_list_back_btn") }}
          </v-btn>
        </v-card>
      </v-row>
    </v-card>
  </v-main>
</template>

<script>
export default {
  data: function() {
    return {
      review_detail_popup: false,
      btNum_1: "1",
      btNum_2: "2",
      btNum_3: "3",
      btNum_4: "4",
      btNum_5: "5",
      arrs: [1, 2, 3, 4, 5],
      list: [],
      start_page2: 5,
      start_page: 0,
      end_page: 0,
      now_page: 0,
      pageSize: 1,
      total_page: 0
    };
  },
  computed: {
    title() {
      return this.$mq == "sm"
        ? this.$ml.get("main_button_03")
        : this.$ml.get("counsel_start_title");
    }
  },
  methods: {
    goto_view: function(idx) {
      //alert("B")
      if (idx == "") {
        return;
      }

      var index = this.list.findIndex(function(item) {
        return item.idx === idx;
      });

      this.input_name_p = this.list[index].tel;
      this.input_img_p = this.list[index].member_img_url;
      // this.input_img_gubun = this.list[index].img_gubun;
      this.input_img_gubun = "N";
      if (this.list[index].title_gubun == "A") {
        this.input_title_p = this.$ml.get("counsel_type_1");
      } else if (this.list[index].title_gubun == "B") {
        this.input_title_p = this.$ml.get("counsel_type_2");
      } else if (this.list[index].title_gubun == "C") {
        this.input_title_p = this.$ml.get("counsel_type_3");
      } else if (this.list[index].title_gubun == "D") {
        this.input_title_p = this.$ml.get("counsel_type_4");
      } else if (this.list[index].title_gubun == "E") {
        this.input_title_p = this.$ml.get("counsel_type_5");
      }

      this.input_content_p = this.list[index].content;
      this.input_color_code = this.list[index].color_code;

      this.$router.push({ hash: "#review_detail" });
    },
    nextPage: function() {
      this.num = this.now_page + 1;
      if (this.total_page >= this.num) {
        if (this.end_page <= this.num) {
          this.start_page = this.num;
          this.end_page = this.start_page + 4;
          if (this.end_page >= this.total_page) {
            this.end_page = this.total_page;
          }
          this.nowData(this.num);
        } else {
          this.nowData(this.num);
        }
      }

      //alert(this.start_page);
    },
    last_nextPage: function() {
      this.nowData(this.total_page);
    },
    prevPage: function() {
      this.num = this.now_page - 1;
      if (this.num >= 1) {
        this.start_page = this.num;
        this.nowData(this.num);
      }
    },
    first_prevPage: function() {
      this.nowData(1);
    },
    nowData: function(num) {
      //api_counseling_list
      var self = this;
      var api = "api_counseling_list.php";
      var param = {
        page: num
      };

      self.$store.dispatch("apiCall", { api, param }).then(() => {
        var data = self.$store.state.api_result;
        if (data == false || data == "err") {
          //error
          alert(
            "정보를 가져오는데 실패하였습니다. 새로 고침 후 다시 시도해 주세요."
          );
        } else {
          try {
            if (data.ret_code == "S") {
              // alert("현재들어온 번호"+num);
              self.now_page = num;
              if (data.total_page_count <= 5) {
                self.start_page = 1;

                self.end_page = data.total_page_count;
              } else if (self.now_page <= 5) {
                self.arrs = [1, 2, 3, 4, 5];
              } else {
                var page_arr_sum =
                  self.now_page % 5 >= 1
                    ? parseInt(self.now_page / 5) * 5 + 5
                    : parseInt(self.now_page / 5) * 5;
                self.arrs = [
                  page_arr_sum - 4,
                  page_arr_sum - 3,
                  page_arr_sum - 2,
                  page_arr_sum - 1,
                  page_arr_sum
                ];
              }

              self.total_page = data.total_page_count;

              var temp = data.list.length % 6;
              if (temp != 0) {
                for (var i = 0; i < 6 - temp; i++) {
                  data.list.push({ idx: "" });
                }
              }

              self.list = data.list;
            } else {
              alert(
                "정보를 가져오는데 실패하였습니다. 새로 고침 후 다시 시도해 주세요."
              );
            }
          } catch (e) {
            alert(
              "정보를 가져오는데 실패하였습니다. 새로 고침 후 다시 시도해 주세요."
            );
          }
        }
      });
    }
  },
  created() {
    this.nowData(1);

    //팝업 띄움
    var target = this.$route.fullPath.substring(
      this.$route.fullPath.indexOf("#")
    );
    if (target == "#review_detail") {
      this.$router.go(-1);
    } else if (target.indexOf("#") < 0) {
      this.review_detail_popup = false;
    }
  },
  watch: {
    $route(to) {
      //팝업 띄움
      var target = to.fullPath.substring(to.fullPath.indexOf("#"));
      if (target == "#review_detail") {
        this.review_detail_popup = true;
      } else if (target.indexOf("#") < 0) {
        this.review_detail_popup = false;
      }
    },
    $mq() {
      this.nowData(this.now_page);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/common";

.bg_card {
  background-image: url("../assets/bg_healing.png");
}

.intro_btn {
  width: 50%;
  height: 60px !important;
  background-color: $m_button_text !important;
  font: normal normal normal 20px Maple !important;
  color: $m_button_text_white;
  border: 2px solid white;
}

.list_bg {
  // background: linear-gradient(
  //   $bg-color-counsel_intro-list-bg-start,
  //   $bg-color-counsel_intro-list-bg-end
  // );

  background-color: $bg-color-counsel_intro-list-bg-start;
}

.item_list_box {
  max-width: 996px;
  padding: 20px 80px 0px 80px !important;
}
.item_div {
  width: 232px;
  height: 210px;
  margin-bottom: 30px;
  margin-left: calc(50% - 116px);
  cursor: pointer;
}

.item_div.off {
  background-color: transparent !important;
}

.item_text {
  padding: 10px 20px 10px 20px;
  width: 232px;
  height: 70px;
  background-color: $bg-color-counsel_intro_list-item-text;
}

.span_text {
  padding: 0px;
  text-align: left;
  font: normal normal bold 19px/30px Noto;
  letter-spacing: 0px;
  color: #d0d0d0 !important;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.gif_size {
  width: 100%;
  height: 140px;
  background-position: center;
}

.paging_round {
  min-width: 72px;
}

.paging_num {
  width: 48px;
  height: 48px;
  font: normal normal bold 20px/35px Noto;
}

.paging_on {
  background-color: $bg-color-counsel-list-table-paging-black-btn-on !important;
  color: $ft-color-counsel-list-table-paging-btn-on;
}

.paging_off {
  border: 3px solid $bg-color-counsel-list-table-paging-black-btn-off-border;
  background-color: $bg-color-counsel-list-table-paging-black-btn-off;
  color: $ft-color-counsel-list-table-paging-black-btn-off;
}

.size_arrow {
  width: 36px !important;
  height: 36px !important;
  font-size: 36px !important;
}

.color_arrow {
  color: $ft-color-counsel_list-table-paging-black !important;
}

.table_title {
  width: 180px;
  height: 60px;
  font: normal normal bold 20px/60px Noto;
  color: $ft-color-counsel_list-table_title;
}

.table_title_long {
  width: 180px;
  height: 339px;
  font: normal normal bold 20px/339px Noto;
  color: $ft-color-counsel_list-table_title;
}

.table_text {
  text-align: left !important;
  padding: 0px 24px 0px 24px;
  width: calc(100% - 186px);
  font: normal normal normal 19px/34px Noto;
  color: $ft-color-counsel_list-table_title;
  display: flex;
  align-items: center;
  word-break: break-all;
}

.table_text.long {
  height: 299px;
  padding: 0px;
  overflow-x: hidden;
  overflow-y: scroll;
  align-items: normal;
}

.bar_right_line {
  width: 6px;
  font: normal normal bold 22px/22px Noto;
  color: $ft-color-counsel_list-table-line-right;
  display: flex;
  align-items: center;
}

.bar_bottom_line {
  background-color: $ft-color-counsel_list-table-line-bottom;
  background-position: top center;
  height: 1px;
}

.table_bg {
  background-color: $bg-color-counsel_list-table;
}

.table_img {
  border: 1px solid #707070;
  margin-right: 20px;
  height: 299px;
  max-width: 369px;
}

.table_title_long_padding {
  padding: 20px 24px 20px 24px;
}

.item_text.sm .span_text {
  font: normal normal bold 3.6vw/5vw Noto;
}

.sm.bg_card {
  background-image: url("../assets/bg_healing_m.png");

  .intro_btn {
    height: 10vw !important;
    font: normal normal normal 4vw Maple !important;
  }

  .paging_round {
    min-width: 11vw;
  }

  .paging_num {
    width: 8vw;
    height: 8vw;
    font: normal normal bold 3.6vw/5vw Noto;
  }

  .size_arrow {
    width: 8vw !important;
    height: 8vw !important;
    font-size: 8vw !important;
  }

  .item_list_box {
    padding: 1vw 3vw !important;
  }

  .item_div {
    padding-right: 0px;
    margin-right: 0px;
    width: 40vw;
    height: 33vw;
    margin: auto;
  }

  .item_text {
    padding: 1vw 3vw 1vw 3vw;
    width: 100%;
    height: 40%;
  }

  .table_img {
    width: 100%;
    height: 50vw;
    max-width: none;
  }

  .table_title {
    width: 16%;
    height: 11vw;
    font: normal normal bold 3.8vw/6vw Noto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .bar_right_line {
    font: normal normal bold 3.8vw/6vw Noto;
  }

  .table_text {
    width: calc(84% - 6px);
    padding: 0px 4%;
    font: normal normal bold 3.8vw/6vw Noto;
  }

  .table_text.long {
    height: 40vw;
  }

  .table_text.long_new_type {
    height: 80vw;
    // padding: 15px;
    overflow-x: hidden;
    overflow-y: scroll;
    align-items: normal;
  }

  .long_new_type {
    height: 60vw;
  }

  .table_title_long_padding {
    padding: 4%;
  }

  .common_btn {
    height: 50px;
  }

  .gif_size {
    width: 100%;
    height: 60%;
    margin-left: 0px;
  }

  .title_root_back {
    display: block !important;
    position: absolute !important;
    width: 8vw !important;
    height: 8vw !important;
    max-width: 40px;
    max-height: 40px;
    // margin: 2vw;
    left: calc(4vw - 3px);

    // top: calc(4vw - 12px);
    // background-image: url("./assets/back_button_o.png");
    background-size: contain;
  }
}
</style>
