<template>
  <v-main class="fullwidth fullheight bg_card" :class="$mq">
    <!-- PC 웹 & 모바일 -->
    <v-card
      flat
      tile
      class="mx-auto"
      min-width="320"
      :max-width="$store.state.web_max_width"
      color="transparent"
    >
      <v-row justify="center" class="title_root">
        <v-col cols="12" class="title_root_text">
          {{ $ml.get("main_button_02") }}
        </v-col>
        <div class="title_root_text_line"></div>
        <v-btn
          elevation="0"
          class="title_root_back"
          icon
          v-on:click="$router.go(-1)"
        >
        </v-btn>
      </v-row>

      <v-row justify="center" class="main_root">
        <v-card
          :class="$mq == 'sm' ? 'rounded-xl' : ''"
          color="transparent"
          width="100%"
        >
          <v-row justify="center" class="main_title_1 d_start_bg">
            <v-btn
              elevation="0"
              class="main_root_back"
              icon
              v-on:click="$router.go(-1)"
            >
            </v-btn>
            <v-col
              cols="12"
              class="main_title_1_text"
              :class="$mq == 'sm' ? '' : 'font_black'"
            >
              {{ title }}
            </v-col>
          </v-row>
          <v-row
            v-if="$mq == 'sm'"
            justify="center"
            class="main_title_1 sub d_start_bg_m"
          >
            <v-col cols="12" class="main_title_1_text d_start_title">
              {{ $ml.get("d_s_title_m") }}
            </v-col>
          </v-row>

          <v-card class="rounded-0" elevation="0" width="100%">
            <v-list class="pa-0">
              <v-list-item :class="$mq !== 'sm' ? 'pb-4 pt-4' : ''">
                <v-list-item-content>
                  <v-list-item-title
                    class="d-flex list_text"
                    v-html="d_s_list_01"
                  ></v-list-item-title>
                </v-list-item-content>
                <div class="border_bottom"></div>
                <v-btn class="list_button" @click="go_pic()">
                  {{ $ml.get("d_s_list_btn") }}
                </v-btn>
              </v-list-item>
              <v-list-item :class="$mq !== 'sm' ? 'pb-4 pt-4' : ''">
                <v-list-item-content>
                  <v-list-item-title
                    class="d-flex list_text"
                    v-html="d_s_list_02"
                  ></v-list-item-title>
                </v-list-item-content>
                <div class="border_bottom"></div>
                <v-btn class="list_button" @click="go_text()">
                  {{ $ml.get("d_s_list_btn") }}
                </v-btn>
              </v-list-item>
            </v-list>
          </v-card>
          <v-row v-if="$mq == 'sm'" justify="center" class="main_title_1 sub">
            <v-col cols="12" class="main_title_1_text"> </v-col>
          </v-row>
        </v-card>
      </v-row>
    </v-card>
  </v-main>
</template>

<script>
//css 적용하기 위해서 import만 씀
// eslint-disable-next-line no-unused-vars
import DiagnosisStartList from "../components/DiagnosisStartList.vue";

export default {
  data: function() {
    return {
      api_result: {},
      pic: {}
    };
  },
  computed: {
    title() {
      return this.$mq == "sm"
        ? this.$ml.get("main_button_02")
        : this.$ml.get("d_s_title");
    },
    d_s_list_01() {
      return this.$mq == "sm"
        ? this.$ml.get("d_s_list_01_m")
        : this.$ml.get("d_s_list_01");
    },
    d_s_list_02() {
      return this.$mq == "sm"
        ? this.$ml.get("d_s_list_02_m")
        : this.$ml.get("d_s_list_02");
    }
  },
  methods: {
    go_pic: function() {
      this.$store.state.diagnosis_data = this.pic;
      this.$router.push("/d_info");
    },
    go_text: function() {
      this.$store.state.diagnosis_data_text = this.api_result;
      this.$router.push("/d_start_text");
    }
  },
  created() {
    //api_survey_list
    var self = this;
    var api = "api_survey_list.php";
    var param = {};

    self.$store.dispatch("apiCall", { api, param }).then(() => {
      var data = self.$store.state.api_result;

      if (data == false || data == "err") {
        // error
        alert(
          "정보를 가져오는데 실패하였습니다. 새로 고침 후 다시 시도해 주세요."
        );
      } else {
        if (data.ret_code == "S") {
          // 데이터 가공
          self.pic = data.list.splice(0, 1)[0];

          self.api_result = data;
        } else {
          alert(
            "정보를 가져오는데 실패하였습니다. 새로 고침 후 다시 시도해 주세요."
          );
        }
      }
    });
  },
  mounted: function() {},
  watch: {}
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/common";

.bg_card {
  background-image: url("../assets/bg_self.png");
}

.d_start_bg {
  background-image: url("../assets/d_sub_bg.png");
  background-position: center;
  background-size: cover;
  height: 180px;
}

.sm.bg_card {
  background-attachment: fixed;
  background-image: url("../assets/bg_self_m.png");

  .d_start_bg {
    background-image: none;
  }

  .d_start_bg_m {
    background-image: url("../assets/d_sub_bg_m.png");
    background-position: center;
    background-size: cover;
    height: 21vw !important;
  }

  .d_start_title {
    font-weight: normal !important;
    color: $ft-diagnosisStart-sub-title !important;
  }
}

/* mobile */
</style>
