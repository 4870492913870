<template>
  <v-col
    class="d-flex flex-column justify-center align-center bg_colorSet"
    :class="$mq"
  >
    <v-img src="@/assets/lan_tour_title.jpg"> </v-img>

    <v-img v-if="$mq !== 'sm'" src="@/assets/lan_tour_00.jpg">
      <button class="lang_tour_button1" @click="open_lan_tour_map(0)"></button>
      <button class="lang_tour_button2" @click="open_lan_tour_map(1)"></button>
      <button class="lang_tour_button3" @click="open_lan_tour_map(2)"></button>
      <button class="lang_tour_button4" @click="open_lan_tour_map(3)"></button>
      <button class="lang_tour_button5" @click="open_lan_tour_map(4)"></button>
      <button class="lang_tour_button6" @click="open_lan_tour_map(5)"></button>
      <button class="lang_tour_button7" @click="open_lan_tour_map(6)"></button>
      <button class="lang_tour_button8" @click="open_lan_tour_map(7)"></button>
    </v-img>

    <v-img v-else src="@/assets/lan_tour_00_m.jpg">
      <button class="lang_tour_button1" @click="open_lan_tour_map(0)"></button>
      <button class="lang_tour_button2" @click="open_lan_tour_map(1)"></button>
      <button class="lang_tour_button3" @click="open_lan_tour_map(2)"></button>
      <button class="lang_tour_button4" @click="open_lan_tour_map(3)"></button>
      <button class="lang_tour_button5" @click="open_lan_tour_map(4)"></button>
      <button class="lang_tour_button6" @click="open_lan_tour_map(5)"></button>
      <button class="lang_tour_button7" @click="open_lan_tour_map(6)"></button>
      <button class="lang_tour_button8" @click="open_lan_tour_map(7)"></button>
    </v-img>

    <!-- <div v-for="(el, index) in youtubeLink_arr" :key="index"> -->
    <!-- <div v-if="count === 0"> -->
    <div class="lan_tour_img_position">
      <v-img class="lan_tour_img" :src="lan_tour_arr[count]"></v-img>
    </div>
    <iframe
      class="urlYoutube"
      content_tap_btn_bg
      :src="youtubeLink_arr[count]"
      title="YouTube video player"
      frameborder="0"
      controls="0"
      allow="
      accelerometer; 
      autoplay; 
      clipboard-write; 
      encrypted-media; 
      gyroscope; 
      picture-in-picture;
      "
      allowfullscreen
    >
    </iframe>
    <!-- </div> -->
    <!-- </div> -->

    <!-- <v-btn class="more_view_btn mb-10" v-on:click="countFunc()">
      {{ button_name_list[count] }}</v-btn
    > -->
  </v-col>
</template>

<script>
export default {
  props: ["title"],
  data: function() {
    return {
      count: 0,
      youtubeLink_arr: [
        "https://www.youtube.com/embed/7s_jBAvNCg0",
        "https://www.youtube.com/embed/yERalriZLFM?start=188",
        "https://www.youtube.com/embed/oLb7ITzfhuo",
        "https://www.youtube.com/embed/2ct9q259mFA",
        "https://www.youtube.com/embed/AlM_PnSPhuk",
        "https://www.youtube.com/embed/koAgcArQx24",
        "https://www.youtube.com/embed/Ov8VZU340hE",
        "https://www.youtube.com/embed/k7Phg5LNi7I"
      ],
      lan_tour_arr: [
        require("@/assets/lan_tour_route_01.jpg"),
        require("@/assets/lan_tour_route_02.jpg"),
        require("@/assets/lan_tour_route_03.jpg"),
        require("@/assets/lan_tour_route_04.jpg"),
        require("@/assets/lan_tour_route_05.jpg"),
        require("@/assets/lan_tour_route_06.jpg"),
        require("@/assets/lan_tour_route_07.jpg"),
        require("@/assets/lan_tour_route_08.jpg")
      ]
    };
  },
  methods: {
    open_lan_tour_map(index) {
      this.count = index;
      window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/common";
.bg_colorSet {
  padding: 0;
  // background-color: $bg-color-contents-tanksdiary;
}

.urlYoutube {
  width: 100%;
  height: 500px;
  margin-bottom: 25px;
}

.thx_send_button {
  width: 50%;
  height: 60px !important;
  background-color: $m_button_text !important;
  font: normal normal bold 20px Noto !important;
  color: $m_button_text_white;
  border: 2px solid white;
}

.lan_tour_img {
}

.lang_tour_button1 {
  position: absolute;
  top: 54%;
  left: 13%;
  width: 210px;
  height: 30px;
  // background-color: red;
}

.lang_tour_button2 {
  position: absolute;
  top: 59%;
  left: 13%;
  width: 500px;
  height: 35px;
  // background-color: blue;
}

.lang_tour_button3 {
  position: absolute;
  top: 64%;
  left: 13%;
  width: 530px;
  height: 35px;
  // background-color: green;
}

.lang_tour_button4 {
  position: absolute;
  top: 70%;
  left: 13%;
  width: 530px;
  height: 35px;
  // background-color: yellow;
}

.lang_tour_button5 {
  position: absolute;
  top: 75%;
  left: 13%;
  width: 350px;
  height: 35px;
  // background-color: orange;
}

.lang_tour_button6 {
  position: absolute;
  top: 81%;
  left: 13%;
  width: 370px;
  height: 35px;
  // background-color: navy;
}

.lang_tour_button7 {
  position: absolute;
  top: 86%;
  left: 13%;
  width: 350px;
  height: 35px;
  // background-color: black;
}

.lang_tour_button8 {
  position: absolute;
  top: 91%;
  left: 13%;
  width: 250px;
  height: 35px;
  // background-color: white;
}

/* mobile */
.sm.bg_colorSet {
  .lan_tour_img_position {
    display: none;
  }

  .urlYoutube {
    width: 100%;
    height: 50vw;
    margin-bottom: 5vw;
  }

  .thx_send_button {
    height: 10vw !important;
    font: normal normal bold 4vw Noto !important;
  }

  .lan_tour_img_position {
    width: 100%;
    height: 30vw;
  }

  .lan_tour_img {
    // position: relative;
    background-size: contain;
    // width: 80vw;
    // height: 50vw;
    margin-bottom: 3vw;
  }

  .lang_tour_button1 {
    position: absolute;
    top: 42%;
    left: 7%;
    width: 35vw;
    height: 5vw;
    // background-color: red;
    // opacity: 0.5;
  }

  .lang_tour_button2 {
    position: absolute;
    top: 48%;
    left: 7%;
    width: 75vw;
    height: 5vw;
    // background-color: blue;
    // opacity: 0.5;
  }

  .lang_tour_button3 {
    position: absolute;
    top: 54%;
    left: 7%;
    width: 82vw;
    height: 5vw;
    // background-color: green;
    // opacity: 0.5;
  }

  .lang_tour_button4 {
    position: absolute;
    top: 60%;
    left: 7%;
    width: 79vw;
    height: 5vw;
    // background-color: yellow;
    // opacity: 0.5;
  }

  .lang_tour_button5 {
    position: absolute;
    top: 66%;
    left: 7%;
    width: 55vw;
    height: 5vw;
    // background-color: orange;
    // opacity: 0.5;
  }

  .lang_tour_button6 {
    position: absolute;
    top: 72.5%;
    left: 7%;
    width: 57vw;
    height: 5vw;
    // background-color: navy;
    // opacity: 0.5;
  }

  .lang_tour_button7 {
    position: absolute;
    top: 78.5%;
    left: 7%;
    width: 55vw;
    height: 5vw;
    // background-color: black;
    // opacity: 0.5;
  }

  .lang_tour_button8 {
    position: absolute;
    top: 84.5%;
    left: 7%;
    width: 40vw;
    height: 5vw;
    // background-color: tan;
    // opacity: 0.5;
  }
}
</style>
