<template>
  <v-main class="fullwidth fullheight bg_card" :class="$mq">
    <!-- PC 웹 & 모바일 -->
    <v-card
      flat
      tile
      class="mx-auto"
      min-width="320"
      :max-width="$store.state.web_max_width"
      color="transparent"
    >
      <v-row justify="center" class="title_root">
        <v-col cols="12" class="title_root_text">
          {{ $ml.get("main_button_02") }}
        </v-col>
        <div class="title_root_text_line"></div>
        <v-btn
          elevation="0"
          class="title_root_back"
          icon
          v-on:click="$router.go(-1)"
        >
        </v-btn>
      </v-row>

      <v-row justify="center" class="main_root">
        <v-card
          :class="$mq == 'sm' ? 'rounded-xl' : 'rounded-b-xl'"
          color="transparent"
          width="100%"
        >
          <v-row justify="center" class="main_title_1">
            <v-btn
              elevation="0"
              class="main_root_back"
              icon
              v-on:click="$router.go(-1)"
            >
            </v-btn>
            <v-col
              cols="12"
              class="main_title_1_text main_title_text_font_color"
            >
              {{ title }}
            </v-col>
          </v-row>

          <v-card
            elevation="0"
            min-height="180"
            class="d-flex align-center justify-center rounded-0 info_text_0 result_text_info"
            v-html="result"
          >
          </v-card>

          <v-row justify="center" class="ma-0 sub_menu">
            <v-col
              :cols="$mq == 'sm' ? 12 : 5"
              class="d-flex sub_menu_position"
              @click="$router.push('/contents')"
            >
              <v-img
                src="@/assets/result_icon_02.jpg"
                :max-width="$mq == 'sm' ? '25vw' : 130"
                :height="$mq == 'sm' ? '23vw' : 100"
                class="rounded-xl"
                cover
              >
              </v-img>
              <v-col class="sub_menu_text_padding">
                <div
                  class="sub_menu_text_sub_root_title"
                  v-html="$ml.get('result_sub_menu_2')"
                ></div>
                <div
                  class="sub_menu_text_sub_root_info"
                  v-html="$ml.get('result_sub_menu_2_info')"
                ></div>
              </v-col>
            </v-col>

            <v-col
              :cols="$mq == 'sm' ? 12 : 1"
              class="pa-0 d-flex justify-center"
            >
              <div class="sub_menu_bar"></div>
            </v-col>
            <v-col
              :cols="$mq == 'sm' ? 12 : 5"
              class="d-flex sub_menu_position"
              @click="$router.push('/counsel_start').catch(() => {})"
            >
              <v-img
                src="@/assets/result_icon_01.jpg"
                :max-width="$mq == 'sm' ? '25vw' : 130"
                :height="$mq == 'sm' ? '23vw' : 100"
                class="rounded-xl"
                cover
              >
              </v-img>
              <v-col class="sub_menu_text_padding">
                <div
                  class="sub_menu_text_sub_root_title"
                  v-html="$ml.get('result_sub_menu_1')"
                ></div>
                <div
                  class="sub_menu_text_sub_root_info"
                  v-html="$ml.get('result_sub_menu_1_info')"
                ></div>
              </v-col>
            </v-col>
          </v-row>

          <v-btn
            class="rounded-0 rounded-b-xl button_2"
            v-on:click="$router.push('/')"
          >
            {{ $ml.get("go_home_btn") }}
          </v-btn>
        </v-card>
      </v-row>
    </v-card>
  </v-main>
</template>

<script>
export default {
  data: function() {
    return {
      title: "",
      result: ""
    };
  },
  computed: {},
  methods: {},
  created() {
    this.title = this.$store.state.diagnosis_data.title;
    this.result = this.$store.state.diagnosis_data_result.message;
  },
  watch: {}
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/common";

.bg_card {
  background-image: url("../assets/bg_self.png");
}

.sub_menu {
  background-color: $bg-color-result-sub-menu;
  cursor: pointer;
}

.sub_menu_bar {
  width: 10px;
  height: 100%;
  background-color: $bg-color-result-sub-menu-border;
}

.sub_menu_text_padding {
  padding: 15px 0px 0px 20px;
}

.sub_menu_text_sub_root_title {
  text-align: left;
  font: normal normal bold 18px/30px Noto;
  color: $ft-color-result-sub-menu;
  letter-spacing: 0px;
}

.sub_menu_text_sub_root_info {
  text-align: left;
  font: normal normal normal 16px/24px Noto;
  color: $ft-color-result-sub-menu-root-info;
  letter-spacing: 0px;
}

.sub_menu_position {
  padding: 30px 0px;
}

/*mobile*/
.sm.bg_card {
  background-image: url("../assets/bg_self_m.png");

  .sub_menu {
    background-color: $bg-color-result-sub-m-menu;
    cursor: pointer;
  }

  .sub_menu_bar {
    width: 100%;
    height: 4px;
  }

  .sub_menu_text_padding {
    padding: 0px 0px 0px 5vw;
  }

  .sub_menu_text_sub_root_title {
    font: normal normal bold 5vw/10vw Noto;
  }

  .sub_menu_text_sub_root_info {
    font: normal normal normal 3.5vw/6vw Noto;
  }

  .sub_menu_position {
    padding: 30px 30px;
  }

  .result_text_info {
    font: normal normal bold 5vw/6vw Noto;
  }
}
</style>
