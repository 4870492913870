<template>
  <v-main class="fullwidth fullheight bg_card" :class="$mq">
    <!-- PC 웹 & 모바일 -->
    <v-card
      flat
      tile
      class="mx-auto"
      min-width="320"
      :max-width="$store.state.web_max_width"
      color="transparent"
    >
      <v-row
        class="d-flex justify-center bg_transparent"
        :class="$mq == 'sm' ? 'main_title_1' : 'title_root'"
      >
        <v-col
          cols="12"
          class="font_black"
          :class="$mq == 'sm' ? 'main_title_1_text' : 'title_root_text'"
        >
          {{ $ml.get("main_button_03") }}
        </v-col>
        <div class="title_root_text_line font_black"></div>
        <v-btn
          elevation="0"
          class="font_black"
          :class="$mq == 'sm' ? 'main_root_back' : 'title_root_back'"
          icon
          v-on:click="$router.go(-1)"
        >
        </v-btn>
      </v-row>

      <v-row justify="center" class="main_root">
        <!-- write -->
        <v-card
          :class="$mq == 'sm' ? 'rounded-b-xl' : 'rounded-b-xl'"
          width="100%"
          class="mainCard"
        >
          <v-img src="@/assets/counsel_write_title.jpg"> </v-img>
          <v-row justify="center" class="main_title_1 big_height">
            <v-col cols="12" class="main_title_1_text font_black" v-html="info">
            </v-col>
          </v-row>

          <v-textarea
            class="mx-5 rounded-0 counsel_write_textarea"
            :background-color="$mq == 'sm' ? '#FFFFFFBB' : '#FFFFFF'"
            :value="input_counsel"
            @change="v => (input_counsel = v)"
            no-resize
            filled
            solo
            :height="$mq == 'sm' ? '80vw' : 280"
            :placeholder="ph"
            counter="19"
          >
          </v-textarea>

          <v-card
            elevation="0"
            class="d-flex flex-column align-center justify-center rounded-0 mx-5 pt-5 main_title_1_text gray counsel_write_input_max"
          >
            <v-checkbox
              v-if="step === 0"
              class="counsel_write_input ma-0 ml-2 pa-0"
              :class="$store.getters.getId == '' ? 'auto_width' : 'auto_width'"
              v-model="checkbox"
              lab
              :label="$ml.get('counsel_write_check_label')"
            >
            </v-checkbox>
            <div
              v-if="step === 0"
              class="pb-5 counsel_write_input_info_text"
              v-html="$ml.get('counsel_write_check_label_info')"
            ></div>
            <v-form
              v-if="$store.getters.getId == ''"
              ref="form"
              class="fullwidth d-flex flex-wrap justify-space-between"
            >
              <v-text-field
                class="counsel_write_input"
                :class="$mq == 'sm' ? 'rounded-lg' : 'rounded-lg'"
                solo
                dense
                :height="$mq == 'sm' ? '10vw' : 58"
                :placeholder="$ml.get('counsel_login_tel_label')"
                persistent-hint
                :rules="tel_rules"
                :value="input_tel"
                @change="v => (input_tel = v)"
                required
              ></v-text-field>

              <v-text-field
                class="counsel_write_input"
                :class="$mq == 'sm' ? 'rounded-lg' : 'rounded-lg'"
                solo
                dense
                :height="$mq == 'sm' ? '10vw' : 58"
                :append-icon="show_pw ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show_pw ? 'text' : 'password'"
                @click:append="show_pw = !show_pw"
                :placeholder="$ml.get('counsel_login_pw_label')"
                persistent-hint
                :rules="pw_rules"
                :value="input_pw"
                @change="v => (input_pw = v)"
                required
              ></v-text-field>

              <v-text-field
                class="counsel_write_input"
                :class="$mq == 'sm' ? 'rounded-lg' : 'rounded-lg'"
                solo
                dense
                :height="$mq == 'sm' ? '10vw' : 58"
                :append-icon="show_pw_check ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show_pw_check ? 'text' : 'password'"
                @click:append="show_pw_check = !show_pw_check"
                :placeholder="$ml.get('counsel_login_pw_check_label')"
                persistent-hint
                :rules="pw_check_rules"
                :value="input_pw_check"
                @change="v => (input_pw_check = v)"
                required
              >
              </v-text-field>
            </v-form>
          </v-card>
          <v-btn class="rounded-0 rounded-b-xl button_4" v-on:click="write()">
            {{ write_btn_text }}
          </v-btn>
        </v-card>
      </v-row>
    </v-card>
    <!-- confirm popup -->
    <v-dialog
      :content-class="'popup ' + $mq"
      v-model="confirm"
      max-width="800"
      :persistent="true"
    >
      <v-card class="pa-5 pb-0 popup_root" style="overflow-y: hidden;">
        <v-card-title class="alert_title">
          <v-spacer />{{ $ml.get("counsel_write_alert_title") }}<v-spacer />
        </v-card-title>

        <v-card-text
          class="pa-0 my-10 d-flex align-center justify-center alert_title_sub_info"
          v-html="$ml.get('counsel_write_confirm_info')"
        >
        </v-card-text>
        <v-row>
          <v-col>
            <v-btn class="mt-5 alert_btn" v-on:click="write_continue()">
              {{ $ml.get("counsel_write_confirm_yes_btn") }}
            </v-btn>
          </v-col>
          <v-col>
            <v-btn class="mt-5 alert_btn alert_cancel_btn" v-on:click="write()">
              {{ $ml.get("counsel_write_confirm_no_btn") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <!-- continue alert popup -->
    <v-dialog
      :content-class="'popup ' + $mq"
      v-model="continue_alert"
      max-width="800"
      :persistent="$store.state.alert_persistent"
    >
      <v-card class="pa-5 pb-0 popup_root" style="overflow-y: hidden;">
        <v-card-title class="alert_title">
          <v-spacer />{{ $ml.get("counsel_write_alert_title") }}<v-spacer />
        </v-card-title>

        <v-card-text
          class="pa-0 my-10 d-flex align-center justify-center alert_title_sub_info"
          v-html="$ml.get('counsel_write_alert_continue')"
        >
        </v-card-text>

        <v-row>
          <v-col>
            <v-btn
              class="mt-5 alert_btn"
              @click="[alert_continue(step), move_to_apply()]"
            >
              {{ $ml.get("alert_ok_btn") }}
            </v-btn>
          </v-col>

          <v-col>
            <v-btn
              class="mt-5 alert_btn alert_cancel_btn"
              @click="alert_continue(0)"
            >
              {{ $ml.get("alert_cancel_btn") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <!-- apply done without gift -->
    <v-dialog
      :content-class="'popup ' + $mq"
      v-model="apply_finished"
      max-width="800"
      :persistent="true"
    >
      <v-card class="pa-5 pb-0 popup_root" style="overflow-y: hidden;">
        <v-card-title class="alert_title">
          <v-spacer />{{ $ml.get("counsel_write_alert_title") }}<v-spacer />
        </v-card-title>

        <v-card-text
          class="pa-0 my-10 d-flex align-center justify-center alert_title_sub_info"
          v-html="$ml.get('counsel_write_success_contents')"
        >
        </v-card-text>

        <v-row>
          <v-btn class="mt-5 mb-0 alert_btn" @click="go_home()">
            {{ $ml.get("go_home_btn") }}
          </v-btn>
        </v-row>
      </v-card>
    </v-dialog>
  </v-main>
</template>

<script>
import { regexCheck } from "../common";

export default {
  data: function() {
    return {
      test: false,
      gift_check: 0,
      step: 0,
      apply_check: false,
      confirm: false,
      apply_finished: false,
      continue_alert: false,
      continue_alert_check: false,
      current_step: 0,
      input_counsel: "",
      checkbox: true,
      show_everyone: true,
      input_tel: "",
      show_pw: false,
      input_pw: "",
      show_pw_check: false,
      input_pw_check: "",
      edit_check: false,
      tel_rules: [
        value => {
          return (
            regexCheck(1, value) || this.$ml.get("counsel_login_tel_rules_1")
          );
        },
        value =>
          (value || "").length == 11 ||
          this.$ml.get("counsel_login_tel_rules_2")
      ],
      pw_rules: [
        value => {
          return (
            regexCheck(1, value) || this.$ml.get("counsel_login_pw_rules_1")
          );
        },
        v => v.length == 4 || this.$ml.get("counsel_login_pw_rules_2")
      ],
      pw_check_rules: [
        value => {
          return (
            value == this.input_pw ||
            this.$ml.get("counsel_login_pw_check_rules_1")
          );
        }
      ]
    };
  },
  computed: {
    info() {
      if (this.step == 1) {
        return this.$mq == "sm"
          ? this.$ml.get("counsel_type_2")
          : this.$ml.get("counsel_type_2");
      } else if (this.step == 2) {
        return this.$mq == "sm"
          ? this.$ml.get("counsel_type_3")
          : this.$ml.get("counsel_type_3");
      } else if (this.step == 3) {
        return this.$mq == "sm"
          ? this.$ml.get("counsel_type_4")
          : this.$ml.get("counsel_type_4");
      } else if (this.step == 4) {
        return this.$mq == "sm"
          ? this.$ml.get("counsel_type_5")
          : this.$ml.get("counsel_type_5");
      } else {
        return this.$mq == "sm"
          ? this.$ml.get("counsel_type_1_m")
          : this.$ml.get("counsel_type_1");
      }
    },
    ph() {
      if (this.step == 1) {
        return this.$mq == "sm"
          ? this.$ml.get("counsel_write_input_ph_2")
          : this.$ml.get("counsel_write_input_ph_2");
      } else if (this.step == 2) {
        return this.$mq == "sm"
          ? this.$ml.get("counsel_write_input_ph_3")
          : this.$ml.get("counsel_write_input_ph_3");
      } else if (this.step == 3) {
        return this.$mq == "sm"
          ? this.$ml.get("counsel_write_input_ph_4")
          : this.$ml.get("counsel_write_input_ph_4");
      } else if (this.step == 4) {
        return this.$mq == "sm"
          ? this.$ml.get("counsel_write_input_ph_5")
          : this.$ml.get("counsel_write_input_ph_5");
      } else {
        return this.$mq == "sm"
          ? this.$ml.get("counsel_write_input_ph_1")
          : this.$ml.get("counsel_write_input_ph_1");
      }
    },
    write_btn_text() {
      if (this.step < 4) {
        return (
          this.$ml.get("counsel_write_btn_text") + "(" + (this.step + 1) + "/5)"
        );
      } else {
        return this.$ml.get("counsel_write_btn_text_final");
      }
    }
  },
  methods: {
    go_home: function() {
      this.$router.replace("/");
    },
    write: function() {
      var self = this;
      self.confirm = false;
      if (this.input_counsel.trim().length < 20) {
        const title = this.$ml.get("counsel_write_alert_title");
        const info = this.$ml.get("counsel_write_alert_error_1");
        this.$store.commit("alert_show", { title, info });
      } else if (this.$refs.form != null && !this.$refs.form.validate()) {
        return;
      } else {
        //loading
        self.$ionic.loadingController
          .create({
            message: self.$ml.get("loading_text")
          })
          .then(l => {
            l.present();
            self.$store.commit("loading_update", l);
          });

        var temp_title_gubun = "A";
        if (this.step == 1) {
          temp_title_gubun = "B";
        } else if (this.step == 2) {
          temp_title_gubun = "C";
        } else if (this.step == 3) {
          temp_title_gubun = "D";
        } else if (this.step == 4) {
          temp_title_gubun = "E";
        }

        //api_counseling_insert
        var api = "api_counseling_insert.php";

        if (self.$store.getters.getId != "") {
          api = "api_counseling_edit.php";
        }

        if (this.step === 0) {
          this.show_everyone = this.checkbox;
        }

        var param = {
          content: encodeURI(this.input_counsel),
          tel: encodeURI(this.input_tel),
          pw: encodeURI(this.input_pw),
          title_gubun: encodeURI(temp_title_gubun),
          gubun: encodeURI(this.show_everyone ? "Y" : "N")
        };

        self.$store.dispatch("apiCall", { api, param }).then(() => {
          //loading hide
          setTimeout(function() {
            try {
              self.$store.state.loading.dismiss();
            } catch (e) {
              //empty
            }
          }, 100);

          var data = self.$store.state.api_result;
          if (data == false || data == "err") {
            // error
            alert(
              "정보를 가져오는데 실패하였습니다. 새로 고침 후 다시 시도해 주세요."
            );
          } else {
            if (data.ret_code == "S") {
              const p_cookie = data.login_token;
              self.$store.dispatch("login", { p_cookie });
              self.step++;
              self.input_counsel = "";
              // self.checkbox = true;
              if (self.step > 4) {
                if (self.show_everyone) {
                  self.$router.replace("/apply_gift");
                } else {
                  self.apply_finished = true;
                }
              }
            } else if (data.ret_code == "FF") {
              const p_cookie = data.login_token;
              self.$store.dispatch("login", { p_cookie }).then(() => {
                if (self.$store.state.user_info.kit_gubun == "Y") {
                  self.confirm = true;
                } else {
                  const title = self.$ml.get("apply_gift_title");
                  const persistent = true;
                  const info = self.$ml.get("apply_gift_alert_already_exist");
                  const fn = function() {
                    self.$router.replace("/");
                    // self.$router.go(-1);
                  };
                  self.$store.commit("alert_show", {
                    title,
                    persistent,
                    info,
                    fn
                  });
                }
              });
            } else {
              const title = self.$ml.get("counsel_write_alert_title");
              const info = data.ret_msg;
              self.$store.commit("alert_show", { title, info });
            }
          }
        });
      }
    },
    write_continue: function() {
      var self = this;
      self.confirm = false;

      var api = "api_my_counseling_list.php";
      var param = {};

      self.$store.dispatch("apiCall", { api, param }).then(() => {
        var data = self.$store.state.api_result;
        // console.log(data);
        if (data == false || data == "err") {
          // error
          alert(
            "정보를 가져오는데 실패하였습니다. 새로 고침 후 다시 시도해 주세요."
          );
        } else {
          if (data.ret_code == "S") {
            let finish = true;
            for (let i = 0; i < data.list.length; i++) {
              if (data.list[i].idx == "") {
                self.step = i;
                self.input_counsel = "";
                // self.checkbox = true;
                finish = false;
                break;
              }
            }
            if (finish && this.show_everyone) {
              self.$router.replace("/apply_gift");
            } else if (finish && !this.show_everyone) {
              self.apply_finished = true;
            }
          } else {
            // error
            alert(
              "정보를 가져오는데 실패하였습니다. 새로 고침 후 다시 시도해 주세요."
            );
          }
        }
      });
    },

    alert_continue(test) {
      this.step = test;
      this.input_counsel = "";
      // this.checkbox = true;
      this.continue_alert = false;
    },

    move_to_apply() {
      if (this.apply_check && this.show_everyone) {
        this.$router.replace("/apply_gift");
      } else if (this.apply_check && !this.show_everyone) {
        this.apply_finished = true;
      }

      this.apply_check = false;
    }
  },
  created() {
    var self = this;
    if (self.$store.getters.getId != "") {
      //api_my_counseling_list
      var api = "api_my_counseling_list.php";
      var param = {};

      self.$store.dispatch("apiCall", { api, param }).then(() => {
        var data = self.$store.state.api_result;
        if (data == false || data == "err") {
          // error
          alert(
            "정보를 가져오는데 실패하였습니다. 새로 고침 후 다시 시도해 주세요."
          );
        } else {
          if (data.ret_code == "S") {
            let finish = true;
            if (data.list[0].gubun !== "N") {
              data.list[0].gubun = "Y";
            }

            this.show_everyone = data.list[0].gubun === "Y" ? true : false;

            for (let i = 0; i < data.list.length; i++) {
              // data.list[i].gubun = data.list[0].gubun;
              if (data.list[i].idx == "" && !this.apply_check) {
                this.edit_check = true;
                self.step = i;
                self.input_counsel = "";
                // self.checkbox = data.list[0].gubun;
                finish = false;
                if (i > 0) {
                  self.continue_alert = true;
                }
                break;
              } else {
                this.gift_check++;
              }

              if (this.gift_check === data.list.length) {
                this.apply_check = true;
                self.step = i;
                self.input_counsel = "";
                // self.checkbox = true;
                finish = false;
                if (i > 0) {
                  self.continue_alert = true;
                }
                this.gift_check = 0;
                break;
              }
            }

            if (!this.edit_check && !this.apply_check) {
              self.step = this.current_step;
              self.input_counsel = "";
              // self.checkbox = self.checkbox;
              finish = false;
              self.continue_alert = false;
            }

            if (finish && this.show_everyone) {
              self.$router.replace("/apply_gift");
            } else if (finish && !this.show_everyone) {
              self.apply_finished = true;
            }
          } else {
            // error
            alert(
              "정보를 가져오는데 실패하였습니다. 새로 고침 후 다시 시도해 주세요."
            );
          }
        }
      });
    }
  },
  watch: {}
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/common";

.bg_card {
  background-image: url("../assets/bg_healing.png");
}

.mainCard {
  background-color: $bg-color-contents-tanksdiary;
  border: solid 2px $ft-color-title-white;
  border-radius: 0px;
}

.main_title_1.big_height {
  height: 160px !important;
  background-color: $bg-color-contents-tanksdiary;
  border-top: solid 2px $ft-color-title-white !important;
}

.main_title_1_text.gray {
  background-color: $bg-color-counsel_write-gray;
}

.counsel_write_textarea {
  font: normal normal normal 18px/24px Noto !important;
  letter-spacing: 0px;
  background-color: $bg-color-counsel_write-textarea;
  border-bottom: dashed 2px $bg-color-counsel_write-border;
}

.counsel_write_input_max {
  padding-left: 140px !important;
  padding-right: 140px !important;
}

.counsel_write_input {
  width: 100%;
  font: normal normal normal 18px/24px Noto !important;
  letter-spacing: 0px;
  margin-bottom: 8px;
}

.counsel_write_input:nth-child(n + 2) {
  max-width: 48%;
}

.auto_width {
  width: auto !important;
}

.list_button {
  height: 58px !important;
  width: 200px;
  background-color: $bg-color-d_s-list_btn !important;
  color: $ft-color-d_s-list_btn !important;
  font: normal normal bold 18px/24px Noto !important;
}

.list_button.on {
  background-color: $bg-color-d_s-list_dark_btn !important;
  color: $ft-color-d_s-list_dark_btn !important;
}

.counsel_write_input_info_text {
  font-size: 20px;
}

.sm.bg_card {
  background-image: url("../assets/bg_healing_m.png");

  .main_title_1.big_height {
    min-height: 32vw !important;
    height: auto !important;
  }

  .counsel_write_textarea {
    font: normal normal normal 4.6vw/7vw Noto !important;
  }

  .counsel_write_input_info_text {
    font-size: 3.5vw;
  }

  .list_button {
    min-width: 0px !important;
    height: 10vw !important;
    width: 26vw;
    font: normal normal normal 3.2vw/5vw Noto !important;
  }

  .main_title_1_text.gray {
    background-color: $bg-color-counsel_write-gray + BB;
  }

  .counsel_write_input_max {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }

  .counsel_write_input {
    font: normal normal normal 3.2vw/5vw Noto !important;
    margin-bottom: 0px;
  }

  .counsel_write_input:nth-child(n + 2) {
    max-width: 100%;
  }
}
</style>
<style lang="scss">
.counsel_write_textarea .v-input__slot {
  box-shadow: none !important;
}

.counsel_write_textarea .v-messages {
  height: 20px;
}

.counsel_write_textarea .v-counter {
  color: rgb(255, 0, 0) !important;
  font-size: 16px;
  padding-top: 5px;
}

.counsel_write_textarea .error--text {
  color: #45a537 !important;
}
</style>
